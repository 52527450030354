import React from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Grid, Box } from "@mui/material";
import { componentContextTheme } from "../components/style";
import Vision from './aboutTabs/vision';
import Targets from './aboutTabs/targets';
import Video from './aboutTabs/video';
import CommanderLetter from './aboutTabs/commanderLetter';
import WebView from './aboutTabs/webView';
import MobileView from './aboutTabs/mobileView';
import ContactForm from './aboutTabs/contactForm';
import useMobile from './../hooks/useMobile';

interface AboutProps {
    // Add any additional props specific to the About component here
}

const items = [
    { id: 0, component: <Vision title="חזון המיזם" />, title: "חזון", englishTitle: "vision" },
    { id: 1, component: <Targets title="מטרות מיזם 'בשביל יהודה'" />, title: "מטרות", englishTitle: "targets" },
    { id: 2, component: <CommanderLetter title="דבר מפקד החטיבה" />, title: "דבר מפקד החטיבה", englishTitle: "commander-letter" },
    { id: 3, component: <Video title="הסרטון שלנו" />, title: "הסרטון שלנו", englishTitle: "video" },
    { id: 4, component: <ContactForm title="צור קשר" />, title: "צור קשר", englishTitle: "contact" },
];

const About: React.FC<AboutProps> = (props) => {
    const isMobile = useMobile();
    const { tabName } = useParams<{ tabName?: string }>();

    const tabId = tabName
        ? items.findIndex(item => item.englishTitle.toLowerCase() === tabName.toLowerCase())
        : 0;

    return (
        <Grid container>
            <Box sx={componentContextTheme}>
                <Grid sx={{ paddingRight: 2, paddingTop: 2, backgroundColor: '#917d62' }}>
                    <Typography variant="h4" color='#fff' textAlign={isMobile ? "center" : "right"}>
                        אודות בשביל יהודה
                    </Typography>
                </Grid>
                <Grid sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#917d62' }}>
                    <Typography variant="h6" color='#fff' textAlign={isMobile ? "center" : "right"}>
                        <Box component="span" borderBottom={4} borderColor='#95c338'>
                            מי אנחנו?
                        </Box>
                    </Typography>
                </Grid>
                <Grid container sx={{ backgroundColor: '#fff' }}>
                    {isMobile ? (
                        <MobileView items={items} tabId={tabId} />
                    ) : (
                        <WebView items={items} tabId={tabId} />
                    )}
                </Grid>
            </Box>
        </Grid>
    );
}

export default About;
