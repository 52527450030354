import React from "react";
import { useParams } from 'react-router-dom';
import { usePlaces } from './../hooks/useMap';
import GoogleMap from "./GoogleMap";
import { MapProvider } from './layouts/MapContext'; 

const Map: React.FC = () => {
    const { places, loaded } = usePlaces();
    const { placeName } = useParams<{ placeName: string }>();

    // Find the place with the title matching placeName
    const selectedMarker = places.find(place => place.name === placeName) || null;

    // Render GoogleMap and pass selectedMarker if it's found
    return (
        <div>
            {loaded && (
                <>
                    <MapProvider places={places}> 
                        <GoogleMap passedMarker={selectedMarker} />
                    </MapProvider>
                </>
            )}
        </div>
    );
};

export default Map;
