import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    InputBase,
    IconButton,
    Chip,
    Card,
    CardContent,
    Button,
} from "@mui/material";
import { Search, Clear } from '@mui/icons-material';
import { armyFallen as armyFallenModel, civilFallen as civilFallenModel } from "../consts/models";
import apiService, { ApiResponse } from "../services/apiService";
import { componentContextTheme } from "../components/style";
import { RESOURCES_URL } from "./../consts/UrlConsts";
import FallenDetail from "./../components/FallenDetails";

interface MemoryProps {
    // Add any additional props specific to the About component here
}

function Memory(props: MemoryProps) {
    const [armyFallen, setArmyFallen] = useState<armyFallenModel[]>([]);
    const [civilFallen, setCivilFallen] = useState<civilFallenModel[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedFilter, setSelectedFilter] = useState<string>("all"); // 'all', 'army', 'civil'
    const [filteredFallen, setFilteredFallen] = useState<(armyFallenModel | civilFallenModel)[]>([]);
    const [selectedFallen, setSelectedFallen] = useState<armyFallenModel | civilFallenModel | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: ApiResponse = await apiService.get("/memory");
                if (response.data) {
                    const parseCivilFallen: civilFallenModel[] = response.data.civilFallen.map((fallen: any) => ({
                        name: fallen.name,
                        city: fallen.city,
                        dateOfFail: new Date(fallen.dateOfFail),
                        dateOfBirth: new Date(fallen.dateOfBirth),
                        description: fallen.description,
                        files: fallen.files,
                    }));
                    setCivilFallen(parseCivilFallen);
                    const parseArmyFallen: armyFallenModel[] = response.data.armyFallen.map((fallen: any) => ({
                        name: fallen.name,
                        major: fallen.major,
                        job: fallen.job,
                        city: fallen.city,
                        dateOfFail: new Date(fallen.dateOfFail),
                        dateOfBirth: new Date(fallen.dateOfBirth),
                        description: fallen.description,
                        files: fallen.files,
                    }));
                    setArmyFallen(parseArmyFallen);
                } else {
                    console.error("Invalid data format in the API response");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                setLoaded(true);
            }
        };

        if (!loaded) {
            fetchData();
        }
    }, [loaded]);

    useEffect(() => {
        let filteredData = [...armyFallen, ...civilFallen];

        if (searchQuery.trim() !== "") {
            filteredData = filteredData.filter(fallen =>
                fallen.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        switch (selectedFilter) {
            case "army":
                filteredData = filteredData.filter(fallen => 'major' in fallen);
                break;
            case "civil":
                filteredData = filteredData.filter(fallen => !('major' in fallen));
                break;
            default:
                // 'all' or default will not filter further
                break;
        }

        setFilteredFallen(filteredData);
    }, [searchQuery, selectedFilter, armyFallen, civilFallen]);

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleFilterChange = (newFilter: string) => {
        setSelectedFilter(newFilter);
    };

    const clearSearchText = () => {
        setSearchQuery("");
    };

    const openDetail = (fallen: armyFallenModel | civilFallenModel) => {
        setSelectedFallen(fallen);
    };

    const closeDetail = () => {
        setSelectedFallen(null);
    };

    const isSearchTextEmpty = searchQuery.trim() === "";

    const isArmyFallen = (fallen: armyFallenModel | civilFallenModel): fallen is armyFallenModel => {
        return (fallen as armyFallenModel).major !== undefined;
    };

    return (
        <Grid >
            <Box sx={componentContextTheme}>
                {selectedFallen ? (
                    <FallenDetail fallen={selectedFallen} onClose={closeDetail} />
                ) : (
                    <>
                        <Grid>
                            <img 
                                src="https://resources.be-shvil-yehuda.com/production/assets/memory+page+design/%D7%99%D7%96%D7%9B%D7%95%D7%A8+%D7%AA%D7%9E%D7%95%D7%A0%D7%94.svg" 
                                alt="Memory Page Design"
                                style={{ width: '100%', height: 'auto' }} // Adjust the width and height as necessary
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: 2 }}>
                            <Typography variant="h4" color='#244a4b' textAlign="center">
                                חללי פעולות האיבה מגזרת יהודה
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ m: 2, border: '2px solid black', backgroundColor: '#fff', color: '#244a4b', borderRadius: '25px' }}>
                                <InputBase
                                    placeholder="חיפוש לפי שם או כינוי..."
                                    inputProps={{ 'aria-label': 'search', style: {  color: '##244a4b' } }}
                                    value={searchQuery}
                                    onChange={handleSearchTextChange}
                                    sx={{ flex: 1, m: 1 }}
                                />
                                {!isSearchTextEmpty && (
                                    <IconButton
                                        color="inherit"
                                        onClick={clearSearchText}
                                        aria-label="clear search"
                                    >
                                        <Clear />
                                    </IconButton>
                                )}
                                <IconButton color="inherit" aria-label="search">
                                    <Search />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            <Chip 
                                label="כלל הנופלים" 
                                onClick={() => handleFilterChange('all')} 
                                variant={selectedFilter === 'all' ? 'filled' : 'outlined'} 
                                style={{
                                    color: selectedFilter === 'all' ? "#fff" : "#286c6c",
                                    backgroundColor: selectedFilter === 'all' ? '#95c338' : '#fff',
                                    marginLeft: 8,
                                    border: '2px solid black'
                                }}
                            />
                            <Chip 
                                label="נופלי החטיבה" 
                                onClick={() => handleFilterChange('army')} 
                                variant={selectedFilter === 'army' ? 'filled' : 'outlined'} 
                                style={{
                                    color: selectedFilter === 'army' ? "#fff" : "#286c6c",
                                    backgroundColor: selectedFilter === 'army' ? '#95c338' : '#fff',
                                    marginLeft: 8,
                                    border: '2px solid black'
                                }}
                            />
                            <Chip 
                                label="נופלי הגזרה" 
                                onClick={() => handleFilterChange('civil')} 
                                variant={selectedFilter === 'civil' ? 'filled' : 'outlined'} 
                                style={{
                                    color: selectedFilter === 'civil' ? "#fff" : "#286c6c",
                                    backgroundColor: selectedFilter === 'civil' ? '#95c338' : '#fff',
                                    marginLeft: 8,
                                    border: '2px solid black'
                                }}
                            />
                        </Grid>
                        <Grid item container sx={{ px: 2, pb: 2 }} spacing={2}>
                            {filteredFallen.length === 0 ? (
                                <Typography variant="h6" color='#244a4b' textAlign="center" sx={{ width: '100%', py: 2 }}>
                                    צוות האתר עובד על דיוק הפרטים, נעדכן בקרוב
                                </Typography>
                            ) : (
                                filteredFallen.map(fallen => (
                                    <Grid key={fallen.name} item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Card sx={{ width: 250, height: 350, backgroundColor: '#244a4b', color: '#fff', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                {fallen.files && fallen.files.path && (
                                                    <img 
                                                        src={`${RESOURCES_URL}${fallen.files.path[0]}`}
                                                        alt="Fallen Soldier" 
                                                        style={{ width: '100%', height: '140px', objectFit: 'scale-down' }} 
                                                        loading="lazy"
                                                    />
                                                )}
                                                <Typography variant="h6">
                                                    {isArmyFallen(fallen) && `${fallen.major} `}
                                                    {fallen.name}
                                                </Typography>
                                                <Typography variant="body1">
                                                    נפל ביום {(fallen.dateOfFail.toLocaleDateString('en-GB'))} 
                                                </Typography>
                                                <Typography variant="body1">
                                                    בן {new Date(fallen.dateOfFail.getTime() - fallen.dateOfBirth.getTime()).getFullYear() - 1970} בנופלו.
                                                </Typography>
                                            </CardContent>
                                            <Button variant="contained" onClick={() => openDetail(fallen)} sx={{ m: 2, backgroundColor: '#98b494', color: '#fff', borderRadius: 4 }}>
                                                סיפור חייו
                                            </Button>
                                        </Card>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </>
                )}
            </Box>
        </Grid>
    );
}

export default Memory;
