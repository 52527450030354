import React from 'react';
import { Typography, AppBar, Toolbar, Grid, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import footerImage from "../assets/footer.jpg";
import { Instagram, Facebook, YouTube, LinkedIn, WhatsApp } from '@mui/icons-material';
import logo from './../assets/logo.png'; 
import useMobile from '../hooks/useMobile';

interface DivStyle {
    position: 'relative';
    background: string;
    minHeight: number;
}

interface ImageStyle {
    backgroundImage: string;
    backgroundSize: string;
    backgroundRepeat: string;
    backgroundPosition: string;
    height: string;
    top: number;
    left: number;
    right: number;
    bottom: number;
}

const Footer: React.FC = () => {
    const isMobile = useMobile();
    const divStyle: DivStyle = {
        position: 'relative',
        background: '#244a4b',
        minHeight: 280,
    };

    const imageStyle: ImageStyle = {
        backgroundImage: `url(${footerImage})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        height: '160px',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };

    const footerContainerStyle: React.CSSProperties = {
        position: 'relative',
        bottom: 0,
        width: '100%',
    };

    return (
        <div style={divStyle}>
            <div style={imageStyle}></div>
            <div style={footerContainerStyle}>
                <AppBar position="static" style={{ background: '#244a4b', boxShadow: 'none' }}>
                    <Toolbar>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body1" align="center" style={{ direction: 'rtl', padding: 2, color: 'white' }}>
                                    <div>
                                        מייל ליצירת קשר:  
                                    </div>
                                    <div>
                                        admin@be-shvil-yehuda.com 
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="body1" align={isMobile ? "center" : "right"} style={{ color: 'white', background: '#244a4b' }}>
                                    {"Copyright © "}
                                    <MuiLink color="inherit" component={RouterLink} to="/login" style={{ textDecoration: 'none' }}>
                                        Beshvil Yehuda
                                    </MuiLink>{" "}
                                    {new Date().getFullYear()}
                                    {"."}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body1" align={isMobile ? "center" : "right"} style={{ color: 'white' }}>
                                    <div>
                                        <Typography variant="body1" component="span" style={{ fontWeight: 'bold' }}>
                                            כללי
                                        </Typography>
                                    </div>
                                    <div>
                                        <MuiLink color="inherit" component={RouterLink} to="/" style={{ textDecoration: 'none' }}>
                                            דף הבית
                                        </MuiLink>
                                    </div>
                                    <div>
                                        <MuiLink color="inherit" component={RouterLink} to="/about" style={{ textDecoration: 'none' }}>
                                            אודות
                                        </MuiLink>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container spacing={2} direction="column" alignItems="center">
                                    <Grid item>
                                        <img src={logo} alt="Logo" style={{ height: '50px', padding: 2 }} />
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                        <Grid item>
                                                <MuiLink href="https://chat.whatsapp.com/LYnRYXWk54yIrkhMvq50Xd" >
                                                    <WhatsApp style={{ color: 'white' }} />
                                                </MuiLink>
                                            </Grid>
                                            <Grid item>
                                                <MuiLink  target="_blank" rel="noopener noreferrer">
                                                    <Instagram style={{ color: 'white' }} />
                                                </MuiLink>
                                            </Grid>
                                            <Grid item>
                                                <MuiLink target="_blank" rel="noopener noreferrer">
                                                    <Facebook style={{ color: 'white' }} />
                                                </MuiLink>
                                            </Grid>
                                            <Grid item>
                                                <MuiLink  target="_blank" rel="noopener noreferrer">
                                                    <YouTube style={{ color: 'white' }} />
                                                </MuiLink>
                                            </Grid>
                                            <Grid item>
                                                <MuiLink target="_blank" rel="noopener noreferrer">
                                                    <LinkedIn style={{ color: 'white' }} />
                                                </MuiLink>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    );
};

export default Footer;
