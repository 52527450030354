import React, { useState } from "react";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import emailjs from 'emailjs-com';

interface ContactFormProps {
    title: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ title }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const templateParams = {
            from_name: name,
            from_email: email,
            message,
            to_name: 'Ori Vered', // This is the recipient's name
        };

        emailjs.send(
            'service_smwuezp', // Replace with your EmailJS service ID
            'template_1xrzjdv', // Replace with your EmailJS template ID
            templateParams,
            'CNbg9xfa4LKDvy4JQ' // Replace with your EmailJS user ID
        ).then((response) => {
            console.log('Email successfully sent!', response.status, response.text);
            clearForm();
        }).catch((err) => {
            console.error('Failed to send email. Error: ', err);
        });

        console.log("Form submitted:", { name, email, message });
    };

    const clearForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    };

    return (
        <Box display="flex" justifyContent="center">
            <form onSubmit={handleSubmit} style={{ width: '50%' }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4" color="#286c6c" textAlign="right">
                            {title}
                        </Typography>
                        <Typography color='#000' variant="h6" textAlign="right" dir="rtl">
                            האתר שלנו כרגע בהרצה ראשונה, אנו מודיעים לבאגים שעלולים לצוץ ולכן נשמח לעזרתכם, אנו עובדים על נגישות לאתר כפי שהחוק מחייב ולכן נשמח לשיתוף הפעולה שלכם
                            <br /> <br />
                            עמלנו רבות על התוכן והמדיה באתר, לכן אם גיליתים שגיאה או שיש ברשותכם מידע עדכני/תמונות/סרטונים נוספים נשמח שתשתפו אותנו
                            <br /> <br />
                            כל תקלה הארה והערה שיש לכם אתם מוזמנים ליצור איתנו קשר טופס הבא.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="שם"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            inputProps={{ style: { textAlign: 'right' } }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="אימייל"
                            type="email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            inputProps={{ style: { textAlign: 'right' } }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="תוכן ההודעה"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            inputProps={{ style: { textAlign: 'right' } }}
                        />
                    </Grid>
                    <Grid item>
                        <Box display="flex" justifyContent="space-between">
                            <Button onClick={clearForm} variant="contained" color="secondary">
                                נקה טופס
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                שלח
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ContactForm;
