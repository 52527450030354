import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import { Add, Remove } from '@mui/icons-material';

interface MobileViewProps {
    items: { id: number; component: React.ReactNode; title: string }[];
    tabId: number;
}

function MobileView({ items, tabId }: MobileViewProps) {
    const [expanded, setExpanded] = useState<number[]>([tabId]);

    useEffect(() => {
        setExpanded([tabId]);
    }, [tabId]);

    const handleToggle = (index: number) => {
        setExpanded((prevExpanded) => {
            if (prevExpanded.includes(index)) {
                return prevExpanded.filter(item => item !== index);
            } else {
                return [...prevExpanded, index];
            }
        });
    };

    return (
        <Grid container spacing={1} sx={{ padding: '8px', borderRadius: '8px' }}>
            {items.map((item, index) => (
                <Grid item xs={12} key={item.id}>
                    <Button
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        backgroundColor: expanded.includes(index) ? '#95c338' : '#92b493',
                        color: 'white',
                        borderRadius: '8px',
                        "&:hover": {
                            backgroundColor: expanded.includes(index) ? '#95c338' : '#92b493',
                        }
                    }}
                        onClick={() => handleToggle(index)}
                        endIcon={<ExpandIcon expanded={expanded.includes(index)} />}
                    >
                        <Typography>{item.title}</Typography>
                    </Button>
                    {expanded.includes(index) && (
                        <Grid item xs={12}>
                            <Box sx={{  borderRadius: '8px', backgroundColor: '#f0f0f0', padding: 2 }}>
                                {item.component}
                            </Box>
                        </Grid>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}

function ExpandIcon({ expanded }: { expanded: boolean }) {
    return expanded ? <Remove /> : <Add />;
}

export default MobileView;
