import React from "react";
import Typography from "@mui/material/Typography";
import {Grid, Box, Divider} from "@mui/material";
import {componentContextTheme} from "../../components/style";

interface CommanderLetterProps {
    // Add any additional props specific to the About component here
    title: string;
}

function  CommanderLetter({ title }: CommanderLetterProps) {
    return (
        <Grid item>

                <Typography variant="h4" color='#286c6c' textAlign="right">
                {title}
                 
                </Typography>
                <Typography color='#000' variant="h6">
                    {/* Your about content goes here */}        
                        אנחנו, מגיני גזרת יהודה, נמצאים כאן בזכות ההתיישבות הישראלית בעת החדשה במרחבי חבל ארץ זה, אשר יונקת את הרוח הגדולה של אבותינו שחיו ופעלו במרחב זה – מאברהם אבינו, כלב בן יפונה, דוד המלך, החשמונאים, מורדי מרד בר כוכבא, ועד דרור ויינברג, מח"ט יהודה שנפל בחברון, וכל לוחמי צה"ל וכמובן עד ראשוני המתיישבים בהר והחיים בו היום וממשיכים מסורת רבת שנים.
                        <br/><br/>
                        כאשר אנו באים לחבר את המפקדים ואת החיילים לשירות משמעותי בחטיבת יהודה, אנו נדרשים לספר את הסיפור – הסיפור הגיאוגרפי וההיסטורי, סיפור המורשת ובעיקר סיפור הערכים, שעליהם מושתת עם ישראל.
                        <br/>
                        <br/>
                        הפרויקט 'בשביל יהודה'  נולד בחטיבת יהודה לפני מספר שנים ונועד לחבר בין המשרתים        
                        בגזרה, ההולכים והמטיילים בה, לבין השטח והמורשת.
                                    <br/>
                                    <br/>
                        "אִם שָׁכַחְתִּי אֶת הַדֶּרֶךְ
                        <br/>
                        הַמּוּכֶּרֶת מִשָּׁנִים
                        <br/>
                        פֹּה וָשָׁם בְּצַד הַדֶּרֶךְ
                        <br/>
                        נִשְׁאֲרוּ לִי סִימָנִים
                        <br/>
                        חֵץ אֶחָד הִנֵּה שָׁלוּחַ
                        <br/>
                        מְצוּיָר בְּגִיר לָבָן:
                        <br/>
                        לֵךְ לְךָ אַחַר הָרוּחַ
                        <br/>
                        שְׁתֵּי פְּסִיעוֹת וָרֶבַע מִכָּאן"
                        <br/>
                        <br/>
                        הכל נמצא כאן, לעיתים באופן ברור ולעיתים בסימנים ורמזים. צריך רק ללכת אחר הרוח ולהבין שהכל ממש כאן, קרוב!
                        <br/>
                        <br/>
                        בפרויקט זה אנו מנגישים את עולמות התוכן דרך החומר הכתוב בספר שיצא לאור לאחרונה, דרך מיפוי ושילוט שיובילו את המטייל והמתבונן במרחב ויאפשרו לו להכיר את העומק המצוי כאן, באמצעות אתר אינטרנט מפורט ונגיש ופרסום ברשתות החברתיות.
                        <br/>
                        <br/>
                        חטיבת יהודה קרויה על שמו של מנהיג שבטי ישראל – יהודה: יהודה מבטא את המנהיגות ואת היכולת לקחת אחריות ולהודות בטעות. יהודה מבטא מלכות ועוצמה המשתקפת בו ובזרעו – כָּלֵב בן יפונה, דוד בן ישי, עתניאל בן קנז ועוד.
                        <br/>
                        <br/>
                        אנו מקווים שכמפקדים וכחיילים נמשיך לפסוע 'בשביל יהודה' בחומר, ברוח ובערכים שאפיינו את הדמויות שהתהלכו כאן. 
                        <br/>
                        החיבור לרוח הגדולה ולערכים הוא זה שמבטא את הסיבה שבגינה נמשיך לבצע את משימותינו בצורה הטובה ביותר.
                        <br/>
                        תודה על הזכות שנפלה בחלקנו לשרת בהגנה על ארץ ישראל בגזרת יהודה. 
                        <br/>
                        רגשי תודה אלו היטיב לבטא בשירו מרדכי זעירא:
                        <br/>
                        <br/>
                        "וְעֵת נָשׁוּב, אַרְצִי, שְׂבֵעֵי גַּעְגּוּעִים,
                        <br/>
                        צְמֵאִים לְחַמָּתֵךְ, לְשֶׁפַע טוֹבוֹתַיִךְ,
                        <br/>
                        יֻחְזַר לָךְ אָז הַשִּׁיר מִלֵּב בָּנִים גֵּאִים,
                        <br/>
                        וְטוֹב יִהְיֶה לִצְעֹד בְּרַחֲבֵי שְׂדוֹתַיִךְ".
                        <br/>
                        <br/>
                        <br/>


                </Typography>
                <Typography color='#000' variant="h6" textAlign="left" fontWeight="bold">
                    
                אל"מ ישי רוזיליו
                <br/>
                מפקד חטיבת יהודה

                </Typography>
        </Grid>
    );
}

export default CommanderLetter;
