// routes.tsx
import Home from "../routes/Home";
import About from "../routes/About";
import Projects from "../routes/Projects";
import Memory from "../routes/Memory";

import Dashboard from "../routes/adminRoutes/Dashboard";
import AddPlace from "../routes/adminRoutes/AddPlace";
import AddCivilMemory from "../routes/adminRoutes/AddCivilMemory";
import AddArmyMemory from "../routes/adminRoutes/AddArmyMemory";
import Login from "../routes/Login";

import PagesRouteLayout from "../components/layouts/PagesRouteLayout"; // Import the layout component
import AuthRouteLayout from "../components/layouts/AuthRouteLayout"; // Import the layout component



/* Regular routes */
const mainRoutes = [
    { path: "/", component: Home, exact: true, str: "מפה", layout: PagesRouteLayout },
    { path: "/memory", component: Memory, str: "נופלי הגזרה", layout: PagesRouteLayout },
    { path: "/about", component: About, str: "אודות", layout: PagesRouteLayout },

];


export function getPathFromComponent(component: React.ComponentType<any>) {
    const foundRoute = publicRoutes.find(route => route.component === component);
    return foundRoute ? foundRoute.path : '';
}

/* Login routes */
const loginRoutes = [
    { path: "/login", component: Login, str: "התחברות", layout: PagesRouteLayout },
    { path: "/logout", component: Dashboard, str: "התנתקות", layout: PagesRouteLayout },
];

/* Api routes */
const apiRoutes = [
    { path: "/:placeName",  component: Home, str: "בית", layout: PagesRouteLayout },
    { path: "/:fallenName",  component: Memory, str: "נופלי הגזרה", layout: PagesRouteLayout },
    { path: "/about-:tabName", component: About, str: "אודות", layout: PagesRouteLayout },
];

/* Admin routes */
const adminRoutes = [
    { path: "/admin/dashboard", component: Dashboard, str: "ניהול", layout: AuthRouteLayout },

    /* Admin Map edit routes */
    { path: "/admin/map/add-place", component: AddPlace, str: "הוספת מיקום", layout: AuthRouteLayout },
    /* Admin memory edit routes */
    { path: "/admin/memory/add-army-memory", component: AddArmyMemory, str: "הוספת חלל צהל", layout: AuthRouteLayout },
    { path: "/admin/memory/add-civil-memory", component: AddCivilMemory, str: "הוספת חלל אזרחי", layout: AuthRouteLayout },
];

// Merge routes
const publicRoutes = [...apiRoutes, ...mainRoutes, ...loginRoutes, ...adminRoutes];

export { mainRoutes, adminRoutes, publicRoutes };
