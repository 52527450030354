//admin consts.tsx

import { FieldComponent } from "../components/FormFieldBuilder"
import { placesCategories } from "../consts/MapConsts";
export const memoryCivilFormFields: Record<string, FieldComponent> = {
    title: {
        label: 'הוספת זיכרון לחלל',
        name: 'title',
        type: 'title',
    },
    civilName: {
        label: 'שם החלל',
        name: 'name',
        type: 'text',
    },
    files: {
        label: 'העלה תמונה',
        name: 'files',
        type: 'filesInput',

    },
    civilCity: {
        label: 'מקום מגורים',
        name: 'city',
        type: 'text',
    },
    dateOfBirth: {
        label: 'תאריך לידה',
        name: 'dateOfBirth',
        type: 'date',
    },
    dateOfFail: {
        label: 'תאריך נפילה',
        name: 'dateOfFail',
        type: 'date',
    },
    description: {
        label: 'אודות החלל',
        name: 'description',
        type: 'textarea',
    },
    submit: {
        label: 'הוספת חלל',
        name: 'submit',
        type: 'submit',
    },
};

export const memoryArmyFormFields: Record<string, FieldComponent> = {
    title: {
        label: 'הוספת זיכרון לחלל',
        name: 'title',
        type: 'title',
    },
    major: {
        label: 'דרגה',
        name: 'major',
        type: 'select',
        options: [
            { value: 'טוראי', label: 'טוראי' },
            { value: 'רב טוראי', label: 'רב"ט' },
            { value: 'סמל', label: 'סמל' },
            { value: 'סמל ראשון', label: 'סמ"ר' },
            { value: 'רב סמל', label: 'רס"ל' },
            { value: 'רב סמל ראשון', label: 'רס"ר' },
            { value: 'רב סמל מתקדם', label: 'רס"מ' },
            { value: 'רב סמל בכיר', label: 'רס"ב' },
            { value: 'סגן משנה', label: 'סג"מ' },
            { value: 'סגן', label: 'סגן' },
            { value: 'סרן', label: 'סרן' },
            { value: 'רב סרן', label: 'רס"ן' },
            { value: 'סגן אלוף', label: 'סא"ל' },
            { value: 'אלוף משנה', label: 'אל"מ' },
            // Add more options as needed
        ],
    },
    job: {
        label: 'תפקיד',
        name: 'job',
        type: 'text',
    },
    soliderName: {
        label: 'שם החלל',
        name: 'name',
        type: 'text',
    },
    files: {
        label: 'העלה תמונה',
        name: 'files',
        type: 'filesInput',

    },
    soliderCity: {
        label: 'מקום מגורים',
        name: 'city',
        type: 'text',
    },
    dateOfBirth: {
        label: 'תאריך לידה',
        name: 'dateOfBirth',
        type: 'date',
    },
    dateOfFail: {
        label: 'תאריך נפילה',
        name: 'dateOfFail',
        type: 'date',
    },
    description: {
        label: 'אודות החלל',
        name: 'description',
        type: 'textarea',
    },
    submit: {
        label: 'הוספת חלל',
        name: 'submit',
        type: 'submit',
    },
};

export const placeFormFields: Record<string, FieldComponent> = {
    title: {
        label: 'הוספת מיקום',
        name: 'title',
        type: 'title',

    },
    name: {
        label: 'שם מיקום',
        name: 'name',
        type: 'text',

    },
    category: {
        label: 'קטגוריה',
        name: 'category',
        type: 'select',
        options: placesCategories.map(cat => ({
            value: cat.name,
            label: cat.label,
        })),
    },
    info: {
        label: 'תיאור המיקום',
        name: 'info',
        type: 'text',

    },
    files: {
        label: 'העלאת קבצים',
        name: 'files',
        type: 'filesInput',

    },
    lat: {
        label: 'קו רוחב',
        name: 'lat',
        type: 'number',

    },
    lng: {
        label: 'קו אורך',
        name: 'lng',
        type: 'number',

    },
    description: {
        label: 'אודות המיקום',
        name: 'description',
        type: 'textarea',

    },
    submit: {
        label: 'הוספת המיקום',
        name: 'submit',
        type: 'submit',

    },
};

export const loginFields: Record<string, FieldComponent> = {
    title: {
        label: 'התחברות',
        name: 'title',
        type: 'title',

    },
    logo: {
        label: 'logo',
        name: 'logo',
        type: 'logo',

    },
    email: {
        label: 'אימייל',
        name: 'email',
        type: 'email',

    },
    password: {
        label: 'סיסמא',
        name: 'password',
        type: 'password',

    },
    submit: {
        label: 'התחברות',
        name: 'submit',
        type: 'submit',
    },
};

export const logoutFields: Record<string, FieldComponent> = {
    title: {
        label: 'התנתקות',
        name: 'title',
        type: 'title',

    },
    submit: {
        label: 'התנתקות',
        name: 'submit',
        type: 'submit',

    },
};