import React from 'react';
import { fieldComponents, FieldComponent, FormValuesType } from './FormFieldBuilder';
import { Grid, Container, Paper, Box } from '@mui/material'; // Added Typography for enhanced error display
import { useForm } from '../hooks/useForm';
import apiService from '../services/apiService';
import { ApiResponse } from '../services/apiService';
import { formTheme} from "./style";


interface FormBuilderProps {
    formFields: Record<string, FieldComponent>;
    endpoint: string;
    onSuccess?: (response: ApiResponse, formValues?: FormValuesType, endpoint?: string) => void; //  formValues Optional
}

function FormBuilder(props: FormBuilderProps) {

    const initialFormValues: FormValuesType = Object.keys(props.formFields).reduce(
        (acc, key) => {
            acc[key] = key.includes('date'||'file') ? null : '';
            return acc;
        },
        {} as FormValuesType
    );

    const { formValues, handleInputChange, handleDateChange, handleSelectChange, handleFileInputChange, resetForm } = useForm(
        initialFormValues
    );

// Function to transform form values
const transformFormValues = (formValues: FormValuesType) => {
    let transformedValues: { [key: string]: string[] | string | number | Date | null } = {};

    for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
            const value = formValues[key];

            // Check if the value is a FileList or an array of File objects
            if (value instanceof FileList || (Array.isArray(value) && value[0] instanceof File)) {
                // Convert to an array of file names
                transformedValues[key] = Array.from(value).map(file => file.name);
            } else {
                // Keep other types (string, Date, null) as they are
                transformedValues[key] = value;
            }
        }
    }

    return transformedValues;
};



// Modified handleSubmit function
const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
        // Use the transform function on formValues for conver FileList to file names list as string[] 
        const transformedFormValues = transformFormValues(formValues);

        const responseData = await apiService.post(props.endpoint, transformedFormValues);
        if (props.onSuccess && responseData) {
            props.onSuccess(responseData, formValues, props.endpoint);

        }
        resetForm();
    } catch (error) {
        console.error('Error submitting form:', error);
    }
};


    return (
        <Grid item style={{ paddingTop: 30, paddingBottom: 30 }}  >

                < Container  component="main" maxWidth="xs" >
                        < Box  sx={formTheme}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}> 
                                    {Object.values(props.formFields).map((field) => (
                                        <Grid item xs={12} key={field.name}>
                                            {fieldComponents[field.type]({
                                                ...field,
                                                formValues,
                                                handleDateChange,
                                                handleInputChange,
                                                handleSelectChange,
                                                handleFileInputChange,
                                            })}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        </Box>
                </Container>
  
        </Grid>
    );
}

export default FormBuilder;
