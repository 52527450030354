import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

interface TargetsProps {
    title: string;
}

const Targets: React.FC<TargetsProps> = ({ title }) => {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h4" color="#286c6c" textAlign="right">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography color="#000" variant="h6" textAlign="right">
                    העצמת החיבור אל שטח גזרת יהודה כחיילים, מתיישבים ומבקרים.
                    <br/>
                    היכרות עם המקומות, הסיפורים והדמויות כנדבך מרכזי בהבנת מעשינו בחבל ארץ זה.
                    <br/>
                    היכרות עם הרוח ועם הערכים הגדולים הבאים לידי ביטוי בסיפורי המקומות והדמויות.
                    <br/>
                    כל זה ע"י יצירת תשתית מידע מונגשת ופשוטה – ספר, שילוט במרחבי יהודה, 
                    יצירת שביל מורשת בחטיבה ואתר אינטרנט.
                    <br/>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Targets;
