import React, { ReactNode } from 'react';
import {componentStyle} from "../style";

interface PagesRouteLayoutProps {
    children: ReactNode;
}

const PagesRouteLayout: React.FC<PagesRouteLayoutProps> = ({ children }) => {
    return (
        <div style={componentStyle}>
            {children}
        </div>

    );
};

export default PagesRouteLayout;
