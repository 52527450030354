// LoadingSpinner.tsx
import React from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import {BackDropSpinnerTheme, LoadingSpinnerTheme} from "./style";

interface LoadingSpinnerProps {
    loading: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ loading },props: CircularProgressProps) => {
    return (
        <Backdrop open={loading} style={BackDropSpinnerTheme}>
            <CircularProgress sx={LoadingSpinnerTheme} />
        </Backdrop>
    );
};

export default LoadingSpinner;
