import React, { useState } from 'react';
import { Avatar, Chip, Box, Typography, Tab, Tabs } from '@mui/material';
import { RESOURCES_URL } from '../consts/UrlConsts';
import { PlaceInfo as PlaceInfoModel } from '../consts/models';
import { placesCategories } from "../consts/MapConsts";

const customWazeIconPath = "https://resources.be-shvil-yehuda.com/assets/35X35/waze-logo.png";

interface PlaceDetailsProps {
  place: PlaceInfoModel | null;
}

const renderDescription = (place: PlaceInfoModel) => {
  return (
      <Box>
      {place.description.map((line, index) => (
        <Typography key={index} paragraph>
          {line}
        </Typography>
      ))}
      </Box>
  );
};

const renderImages = (place: PlaceInfoModel) => {
  const filePaths = Array.isArray(place?.files?.path) ? place.files.path : [];
  const images = filePaths.filter(filePath => /\.(jpg|png)$/i.test(filePath)).slice(1); // Start from the second image
  if (images.length === 0) {
    return (
      <Typography>
        אין תמונה ברשותנו למיקום זה, אם יש בידכם אחת כזו נשמח שתשלחו לנו
      </Typography>
    );
  }
  return images.map(imagePath => (
    <img key={imagePath} src={`${RESOURCES_URL}${imagePath}`} alt="Place" style={{ width: '100%', maxWidth: '100%', height: 'auto' }} />
  ));
};

const renderVideos = (place: PlaceInfoModel) => {
  const filePaths = Array.isArray(place?.files?.path) ? place.files.path : [];
  const videos = filePaths.filter(filePath => /\.mp4$/i.test(filePath));
  if (videos.length === 0) {
    return (
      <Typography>
        אין ברשותנו סרטון למיקום זה, אנחנו עובדים על זה
      </Typography>
    );
  }
  return videos.map(videoPath => (
    <video key={videoPath} src={`${RESOURCES_URL}${videoPath}`} controls style={{ paddingTop: 5, paddingBottom: 5, width: '100%', maxWidth: '100%', height: 'auto' }} />
  ));
};

const TAB_LABELS = [
  { label: "סקירה כללית", render: renderDescription },
  { label: "תמונות", render: renderImages },
  { label: "סרטונים", render: renderVideos },
];

const PlaceDetails: React.FC<PlaceDetailsProps> = ({ place }) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!place) return null;
  const jpgFiles = Array.isArray(place?.files?.path) ? place.files.path.find(filePath => /\.(jpg)$/i.test(filePath)) : null;
  const firstImagePath = jpgFiles ? `${RESOURCES_URL}${jpgFiles}` : null;

  const category = placesCategories.find(cat => cat.name === place.category);

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="auto" sx={{ overflowX: 'hidden' }} p={1}>
      <Box sx={{ width: '100%', margin: '10px' }}>
        {/* Render title */}
        <Typography variant="h4">{place.name}</Typography>
        <Typography variant="h6">{place.info}</Typography>
      </Box>
      {/* Render icons */}
      <Box sx={{ width: '100%', p: '2', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {category && (
          <Chip
            key={place.category}
            label={category.label}
            dir="rtl"
            avatar={
              <Avatar
                dir="rtl"
                src={`https://resources.be-shvil-yehuda.com/production/assets/icons/Map_Pure/35X35_SVG/icon_${category.name}.svg`}
                sx={{ width: 20, height: 20 }}
              />
            }
            variant="filled"
            sx={{ ml: 1, mb: 1, backgroundColor: category.color, color: '#fff', '& .MuiChip-avatar': { marginRight: '9px', display: 'flex', alignItems: 'right', justifyContent: 'right', width: 17, height: 17, '& img': { objectFit: 'scale-down' } } }}
          />
        )}
        <Box pl={1}>
        <a href={`https://www.waze.com/ul?ll=${place.location.lat},${place.location.lng}`} target="_blank" rel="noopener noreferrer">
          <img src={customWazeIconPath} alt="Waze" style={{ width: 35, height: 35 }} />
        </a>
        </Box>
      </Box>
      {/* Render first image */}
      {firstImagePath && (
        <img src={firstImagePath} alt="Place" style={{ width: '100%', maxWidth: '100%', height: 'auto' }} />
      )}
      {/* Render tabs */}
      <Tabs
        value={tabIndex}
        onChange={(event, newValue) => setTabIndex(newValue)}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: 'white'
          }
        }}
        textColor="inherit"
        centered
      >
        {TAB_LABELS.map((label, index) => (
          <Tab key={index} label={label.label} />
        ))}
      </Tabs>

      {/* Render tab content with scrolling */}
      <Box flex={1}  p={2}>
        {TAB_LABELS[tabIndex].render(place)}
      </Box>
    </Box>
  );
};

export default PlaceDetails;
