import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import apiService from "../../services/apiService";
import useAuth from "../../hooks/useAuth";
import {logoutFields} from "../../consts/adminConsts";
import FormBuilder from "../../components/FormBuilder";

interface AdminProps {
    // Add any additional props specific to the About component here
}

function Dashboard(props: AdminProps) {
    const endpoint = "/logout";
    const { logout } = useAuth();

    return (
        <FormBuilder formFields={logoutFields} endpoint={endpoint} onSuccess={logout} />

    );
}

export default Dashboard;
