import { useState, useEffect } from 'react';
import apiService, { ApiResponse } from '../services/apiService';
import { useNavigate } from 'react-router-dom';


const useAuth = () => {
    const navigate = useNavigate();

    const login = (response: ApiResponse): void => {
        if (response) {
            const token = response.data.token;
            setAuthToken(token);
            navigate('/admin/dashboard');
        } else {
            console.error('Error submitting login - token incorrect:');
        }
    };

    const logout = (response: ApiResponse): void =>{
        clearAuthToken();
        navigate('/');
    };
    
    const getAuthToken = () => {
        const token = localStorage.getItem('accessToken');
        const expirationTime = localStorage.getItem('tokenExpiration');
        return { token, expirationTime };
    };
    
    const isAuthenticated = () => {
        const { token, expirationTime } = getAuthToken();
        const now = new Date().getTime();
        if( token && expirationTime && now < parseInt(expirationTime)){ 
            return true;
        }
        else {
            clearAuthToken();
            return false;
        }
    };
    

    const setAuthToken = (token: string) => {
        const expiresIn = 3600000; // 1 hour in milliseconds
        const expirationTime = new Date().getTime() + expiresIn;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('tokenExpiration', expirationTime.toString());
        apiService.setAuthToken(token);
    };
    

    const clearAuthToken = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tokenExpiration');
        apiService.setAuthToken(null);
    };

    return { login, logout, isAuthenticated };
};

export default useAuth;
