import * as React from 'react';
import { placeFormFields } from '../../consts/adminConsts'; // Update path accordingly
import FormBuilder from "../../components/FormBuilder";
import {getPathFromComponent} from "../../consts/router";
import useUpload from '../../hooks/useUpload';

interface AddPlaceProps {}

function AddPlace(props: AddPlaceProps) {
    const endpoint = getPathFromComponent(AddPlace);
    const { uploadFiles } = useUpload();
    return (
        <div>
            {/* Pass formFields as a prop to FormBuild */}
            <FormBuilder formFields={placeFormFields} endpoint={endpoint} onSuccess={uploadFiles} />
        </div>
    );
};

export default AddPlace;