import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RESOURCES_URL } from "../consts/UrlConsts";
import { armyFallen as armyFallenModel, civilFallen as civilFallenModel } from "../consts/models";
import axios from 'axios';
import useMobile from './../hooks/useMobile';

interface FallenDetailProps {
    fallen: armyFallenModel | civilFallenModel | null;
    onClose: () => void;
}

interface DateAndAge {
    age: number;
    regularDate: string;
    hebrewDate: string;
}

// Helper function to format dates into Hebrew calendar and calculate age
const formatDateAndAge = async (dateOfBirth: Date | string, dateOfDeath: Date | string): Promise<DateAndAge> => {
    let dob = dateOfBirth instanceof Date ? dateOfBirth : new Date(dateOfBirth);
    let dod = dateOfDeath instanceof Date ? dateOfDeath : new Date(dateOfDeath);

    if (isNaN(dob.getTime()) || isNaN(dod.getTime())) {
        console.error("Invalid dates provided");
        return { age: 0, regularDate: '', hebrewDate: '' };
    }

    const age = new Date(dod.getTime() - dob.getTime()).getUTCFullYear() - 1970;

    // Fetch Hebrew date from Hebcal API
    const response = await axios.get(`https://www.hebcal.com/converter?cfg=json&gy=${dod.getFullYear()}&gm=${dod.getMonth() + 1}&gd=${dod.getDate()}&g2h=1`);
    const hebrewDateOfDeath = response.data.hebrew;

    return {
        age,
        regularDate: dod.toLocaleDateString('en-GB'), // You can adjust the locale
        hebrewDate: hebrewDateOfDeath
    };
};

const FallenDetail: React.FC<FallenDetailProps> = ({ fallen, onClose }) => {
    const isMobile = useMobile();
    const [dateAndAge, setDateAndAge] = useState<DateAndAge | null>(null);

    useEffect(() => {
        if (fallen && fallen.dateOfFail && fallen.dateOfBirth) {
            formatDateAndAge(fallen.dateOfBirth, fallen.dateOfFail).then(setDateAndAge);
        }
    }, [fallen]);

    if (!fallen || !dateAndAge) return null;

    const isArmyFallen = (fallen: armyFallenModel | civilFallenModel): fallen is armyFallenModel => {
        return (fallen as armyFallenModel).major !== undefined;
    };

    const { age, regularDate, hebrewDate } = dateAndAge;

    return (
        <Grid container direction="column">
            <Grid container item alignItems="center" sx={{ padding: 2, backgroundColor: '#917d62' }}>
                <IconButton onClick={onClose}>
                    <ArrowForwardIcon sx={{ color: '#fff' }} />
                </IconButton>
                <Typography variant="h4" color='#fff' textAlign="right" sx={{ flexGrow: 1 }}>
                    חללי גזרת יהודה
                </Typography>
            </Grid>

            <Grid container item sx={{ backgroundColor: '#244a4b', padding: 2 }}>
                <Grid item xs={isMobile ? 12 : 3}>
                    {fallen.files && fallen.files.path && (
                        <img
                            src={`${RESOURCES_URL}${fallen.files.path[0]}`}
                            alt="Fallen Soldier"
                            style={{ width: 'auto', height: '150px', objectFit: 'contain' }}
                        />
                    )}
                </Grid>
                <Grid item xs={isMobile ? 12 : 5}>
                    <Typography variant="h4">
                        {isArmyFallen(fallen) && `${fallen.major} `}
                        {fallen.name}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6">
                        נפל ביום {hebrewDate} ({regularDate})
                    </Typography>
                    <Typography variant="h6">
                        בן {age} בנופלו.
                    </Typography>
                    <Typography variant="h6">
                        מ{fallen.city}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper' }}>
                    <Grid container sx={{ m: 2 }}>
                        <Grid item xs={isMobile ? 12 : 2} sx={{
                            p: 2,
                            m: 1,
                            minHeight: '260px',
                            maxHeight: '620px',
                            bgcolor: '#286c6c',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography
                                sx={{
                                    width: 'auto',
                                    color: 'white',
                                    bgcolor: '#90c340',
                                    p: 1,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                }}
                            >
                                סיפור חייו
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 9} sx={{ p: 5, m: 1, backgroundColor: '#a0b494' }}>
                            <Typography variant="h4" sx={{ color: '#244a4b' }}>
                                סיפור חייו
                            </Typography>
                            <Box sx={{ color: '#000' }}>
                                {Array.isArray(fallen.description) ? (
                                    fallen.description.map((line, index) => (
                                        <Typography key={index} paragraph>
                                            {line}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography paragraph>{fallen.description}</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FallenDetail;
