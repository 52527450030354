// src/reducers/mapReducer.tsx
import { MapState, MapAction, placesCategories } from '../../consts/MapConsts';

// Create an initial filter object with all categories set to true
const initialCategoriesFilter: { [key: string]: boolean } = {};
for (const category of placesCategories) {
  initialCategoriesFilter[category.name] = true;
}

export const initialState: MapState = {
  CategoriesFilter: initialCategoriesFilter,
  selectedMarker: null,
  filteredMarkers: [], // Initialize as empty array
  drawerState: 'closed',
};

export function mapReducer(state: MapState, action: MapAction): MapState {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, CategoriesFilter: action.payload };
    case 'SET_SELECTED_MARKER':
      return { ...state, selectedMarker: action.payload };
    case 'SET_FILTERED_MARKERS':
      return { ...state, filteredMarkers: action.payload };
    case 'SET_DRAWER_STATE':
      return { ...state, drawerState: action.payload };
    default:
      return state;
  }
}
