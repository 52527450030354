import React, { useRef, useState, useEffect } from "react";
import Map from "../components/Map";

interface HomeProps {
  // Add any additional props specific to the Home component here
}
const HomePage: React.FC<HomeProps> = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Iterate through elements to find the current active element
      elementsRef.forEach((elementRef, index) => {
        if (
          elementRef.current &&
          elementRef.current.offsetTop <= scrollPosition + 100 // Adjust this threshold as needed
        ) {
          setCurrentElement(index);
        }
      });
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run this effect only once

  const elementsRef = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    // Add more refs if you have additional elements
  ];

  const [currentElement, setCurrentElement] = useState(0);

  const scrollToElement = (index: number) => {
    elementsRef[index]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollDown = () => {
    if (currentElement < elementsRef.length - 1) {
      setCurrentElement(currentElement + 1);
      scrollToElement(currentElement + 1);
    }
  };

  const handleScrollUp = () => {
    if (currentElement > 0) {
      setCurrentElement(currentElement - 1);
      scrollToElement(currentElement - 1);
    }
  };

  const videoWrapperStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "0",
    paddingBottom: "56.25%", // 16:9 aspect ratio
  };

  const videoStyle: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
  };

  const mapStyle: React.CSSProperties = {
    minHeight: "100vh", // Make sure the map takes up the full viewport height
  };

  return (
    <div>
      {/* <Box
        style={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          top: "50%",
          transform: "translateY(-50%)",
          left: 10, // Adjust the left position as needed
          zIndex: 1000, // Ensure icons are on top of other elements
        }}
      >
      {currentElement > 0 && (
        <Avatar sx={{ backgroundColor: "#244a4b" }}>
          <IconButton onClick={handleScrollUp}>
            <ArrowUpwardIcon sx={{ color: "white" }} />
          </IconButton>
        </Avatar>
      )}
      {currentElement < elementsRef.length - 1 && (
        <Avatar sx={{ backgroundColor: "#244a4b" }}>
          <IconButton onClick={handleScrollDown}>
            <ArrowDownwardIcon sx={{ color: "white" }} />
          </IconButton>
        </Avatar>
      )}
      </Box> */}

      <div style={mapStyle} ref={elementsRef[0]}>
        <Map />
      </div>

      {/* <div style={videoWrapperStyle} ref={elementsRef[1]}>
        <iframe
          src="https://player.vimeo.com/video/854634359?h=6d11db5b45"
          style={videoStyle}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}
    </div>
  );
};

export default HomePage;
