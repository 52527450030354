import { PlaceInfo as PlaceInfoModel } from "./models";
export type PolygonData = {
    name: string;
    color: string;
    coordinates: { lat: number; lng: number }[];
  }[];
  
// Define the DrawerState type to specify the allowed states
export type DrawerState = 'closed' | 'halfMobile' | 'fullMobile' | 'web' ;

export const placeCategory = {
  place: "אתרים בגזרה",
  yishuv: "ישובים בגזרה",
  village: "כפרים בגזרה",
};

export const placesCategories = [
  { label: "אתרים", name: "place", color : '#99542d'},
  { label: "תיירות", name: "tour", color : '#fbba3a' },
  { label: "פינת שקשוקה", name: "shakshooka", color : '#1b4d24' },
  { label: "חוות", name: "farm", color : '#5ac5f2' },
  { label: "ישובים", name: "yishuv", color : '#2b69a0' },
  { label: "כפרים", name: "village", color : '#c71617' },
  { label: "מורשת קרב", name: "battle", color : '#808122' },
];


export interface MapState {
  CategoriesFilter: Record<string, boolean>;
  selectedMarker: PlaceInfoModel | null;
  filteredMarkers: PlaceInfoModel[]; // Define the type for filteredMarkers
  drawerState: DrawerState;
}

export type MapAction =
  | { type: 'SET_FILTER'; payload: Record<string, boolean> }
  | { type: 'SET_SELECTED_MARKER'; payload: PlaceInfoModel | null }
  | { type: 'SET_FILTERED_MARKERS'; payload: PlaceInfoModel[] } // New action type
  | { type: 'SET_DRAWER_STATE'; payload: 'closed' | 'halfMobile' | 'fullMobile' | 'web' };