import apiService, { ApiResponse } from '../services/apiService';
import Uploader from '../services/UploadService';

interface Options {
    useTransferAcceleration: boolean;
    chunkSize?: number;
    threadsQuantity?: number;
    file: File;
    filePath?: string;

  }
  
const useUpload = () => {

    const uploadFiles = (response: ApiResponse, formValues: any, endpoint?: string): void => {

        if (response) {
            console.log(response)
            const filesPath = response.data.files.path;
            const files = formValues.files;
            for (let i = 0; i < files.length; i++)
            {
                const uploaderOptions: Options = {
                    file: files[i],
                    filePath: filesPath[i],
                    chunkSize: 5,//(MB)
                    threadsQuantity: 5,
                    useTransferAcceleration: true 
                }
                const uploader = new Uploader(uploaderOptions)
                uploader.start();  
            };
            //apiService.post(`${endpoint}/uploaded`, response.data.id);
        } else {
            console.error('Error upload files');
        }
    };

    return { uploadFiles };
};

export default useUpload;
