import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingSpinner from './components/LoadingSpinner';
import { getAxiosInstance } from './services/apiService';
import {LoadScript} from "@react-google-maps/api";
import {publicRoutes} from "./consts/router";
import {Grid} from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
const mapApi = process.env.MAP_API  || "AIzaSyA2J7hqSJfkcIYA4Ahu71PkHHdZWupj1bY"

const theme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily: "'Noto Sans Hebrew', sans-serif", // Default font for body text
        fontSize: 13,
        h1: { fontFamily: "'Secular One', sans-serif" }, // Titles font
        h2: { fontFamily: "'Secular One', sans-serif" },
        h3: { fontFamily: "'Secular One', sans-serif" },
        h4: { fontFamily: "'Secular One', sans-serif" },
        h5: { fontFamily: "'Secular One', sans-serif" },
        h6: { fontFamily: "'Secular One', sans-serif" },
        // Apply this style to other title elements as needed
      },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                },
            },
        },
    },
});


const App: React.FC = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const requestInterceptor = getAxiosInstance().interceptors.request.use((config) => {
            setLoading(true); // Show spinner when a request is made
            return config;
        });

        const responseInterceptor = getAxiosInstance().interceptors.response.use(
            (response) => {
                setLoading(false); // Hide spinner when response is received
                return response;
            },
            (error) => {
                setLoading(false); // Hide spinner on response error
                return Promise.reject(error);
            }
        );

        return () => {
            // Cleanup: Remove interceptors when component unmounts
            getAxiosInstance().interceptors.request.eject(requestInterceptor);
            getAxiosInstance().interceptors.response.eject(responseInterceptor);
        };
    }, []);


    return (
        <ThemeProvider theme={theme}>
        <Router>
         
                <Grid  style={{ minHeight: '100vh' }}>
                    <Grid item>
                        <Header />
                    </Grid>
                    <Grid item container justifyContent="center">
                        {/* Loader */}
                        {<LoadingSpinner loading={loading} />}
                    </Grid>
                    <Grid item >
                    <LoadScript googleMapsApiKey={mapApi} loadingElement={<LoadingSpinner loading={true} />}>

                        <Routes>
                            {/* All Routes */}
                            {publicRoutes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={<route.layout><route.component /></route.layout>}
                                />
                            ))}
                            {/* Catch-all route */}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </LoadScript>
                    </Grid>
                    <Grid item style={{ marginTop: 'auto' }}>
                        <Footer />
                    </Grid>
                </Grid>
          
        </Router>
    </ThemeProvider>
    );
};

export default App;

