import React, { useState } from "react";
import { Box, Typography, Divider, Collapse } from '@mui/material';
import { PlaceInfo as PlaceInfoModel } from "../consts/models";
import { placesCategories } from "../consts/MapConsts";
import { useNavigate } from "react-router-dom";
import { useMapContext } from "./layouts/MapContext";
import SearchBox from './SearchBox';

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: '5px',
};

const paperStyle = {
  marginTop: '10px',
  borderRadius: '5px',
  paddingY: '10px',
  maxHeight: '500px',

};

const innerBoxStyle = {
  marginBottom: '10px',
  overflowY: 'auto',
};

const itemImageStyle = {
  marginRight: '15px',
  width: '24px',
  height: '24px'
};

const innerTypographyStyle = {
  marginBottom: '5px',
  fontWeight: 'bold',
};

const markerBoxStyle = {
  display: 'flex',
  color: '#000',
  alignItems: 'center',
  margin: '3px 0',
  paddingLeft: '5px',
};

const ListedPlaces: React.FC<{ }> = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useMapContext();
  const { CategoriesFilter, selectedMarker, filteredMarkers } = state;

  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  const handleToggleCategory = (category: string) => {
    setExpandedCategories(prevExpanded => {
      if (prevExpanded.includes(category)) {
        return prevExpanded.filter(cat => cat !== category);
      } else {
        return [...prevExpanded, category];
      }
    });
  };

  const handleMarkerClick = (marker: PlaceInfoModel) => {
    if (selectedMarker && marker._id === selectedMarker._id) {
      navigate(`/`);
      dispatch({ type: 'SET_SELECTED_MARKER', payload: null });
    } else {
      navigate(`/${marker.name}`);
      dispatch({ type: 'SET_SELECTED_MARKER', payload: marker });
    }
  };

  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (text: string) => {
    setSearchText(text.toLowerCase());
  };

  const filteredMarkersState = filteredMarkers.filter(marker =>
    marker.name.toLowerCase().includes(searchText)
  );
  const groupedMarkers: { [category: string]: PlaceInfoModel[] } = filteredMarkersState.reduce((acc, marker) => {
    acc[marker.category] = acc[marker.category] || [];
    acc[marker.category].push(marker);
    return acc;
  }, {} as { [category: string]: PlaceInfoModel[] });

  return (
    <Box sx={paperStyle}>
      <Box sx={{ bottom: 0 }}>
        <SearchBox searchText={searchText} onSearchTextChange={handleSearchTextChange} />
      </Box>
      {Object.entries(groupedMarkers).map(([category, markers], index, array) => (
         <Box key={category} sx={innerBoxStyle}>
          <Box sx={boxStyle} onClick={() => handleToggleCategory(category)}>
            <img
              src={`https://resources.be-shvil-yehuda.com/production/assets/icons/Map_Location/33X33_SVG/icon_${category}.svg`}
              alt={category}
              style={itemImageStyle}
            />
            <Typography variant="h6" sx={innerTypographyStyle}>
              {placesCategories.find(item => item.name === category)?.label || category}
            </Typography>
          </Box>
          <Collapse in={expandedCategories.includes(category)} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d4d4d4', color: '#000' }}>
              {markers.map((marker, idx) => (
                <React.Fragment key={marker.name}>
                  {idx !== 0 && <Divider sx={{ width: '100%', backgroundColor: '#fff' }} />}
                  <Box
                    sx={markerBoxStyle}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleMarkerClick(marker)}
                  >
                    <Typography sx={{ fontSize: 'small', marginRight: '5px' }}>{marker.name}</Typography>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
          {index !== array.length - 1 && <Divider sx={{ backgroundColor: '#fff', width: '100%' }} />}
        </Box>
      ))}
    </Box>
  );
};

export default ListedPlaces;
