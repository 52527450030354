import React, { ReactNode } from 'react';
import useAuth from '../../hooks/useAuth';
import AdminTabBar from '../AdminTabBar';
import { Navigate } from 'react-router-dom';
import { componentStyle, componentContextTheme } from "../style";
import { Box } from '@mui/material';

interface AuthRouteLayoutProps {
    children: ReactNode;
}

const AuthRouteLayout: React.FC<AuthRouteLayoutProps> = ({ children }) => {
    const { isAuthenticated } = useAuth();

    return (
        <div style={componentStyle}>
            {isAuthenticated() ? (
                <Box sx={componentContextTheme}>
                    <AdminTabBar />
                    {/* Render children - admin routes components only if authenticated */}
                    {children}    
                </Box>
            ) : (
                <div>
                    {/* Authentication failed, Navigate to Login page */}
                    <Navigate to="/login" />
                </div>
            )}
        </div>
    );
};

export default AuthRouteLayout;
