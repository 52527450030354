import React, { useMemo, useEffect } from "react";

import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { PlaceInfo as PlaceInfoModel } from "../consts/models";
import { PolygonData, placesCategories } from "../consts/MapConsts";
import polygonData from "./../assets/polygons.json";

import { Avatar, Chip, Box } from '@mui/material';

import MobileDrawer from "./MobileDrawer";

import {useNavigate } from "react-router-dom";
import { useMapContext } from "../components/layouts/MapContext"

import useMobile from './../hooks/useMobile';
interface GoogleMapProps {

  passedMarker: PlaceInfoModel | null;
}


const Map: React.FC<GoogleMapProps> = ({ passedMarker }) => {
  useEffect(() => {
    // Check if a marker is passed
    if (passedMarker) {
      // Dispatch the selected marker
      dispatch({ type: 'SET_SELECTED_MARKER', payload: passedMarker });
    }
  }, [passedMarker]);

  const containerStyle = {
    width: "99vw", // Full viewport width
    height: "100vh", // Full viewport height
  };

  const checkboxContainerStyleWeb = {
    overflowX: 'auto',
    direction: "rtl",
    paddingLeft: '10px',
    borderRadius: '2px',
    position: 'absolute',
    top: '76px', 
    left: '50%',
    whiteSpace: 'nowrap',  // Ensures the chips stay in a single line
    transform: 'translateX(-66%)', // Adjust this value based on half of the container's width
    width: '70%', 
    zIndex: 100,
    WebkitOverflowScrolling: 'touch',  // Better scrolling on iOS devices

    // Styles to hide the scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',  // Hide scrollbar for Chrome, Safari and Opera
    },
    scrollbarWidth: 'none',  // Hide scrollbar for Firefox
    msOverflowStyle: 'none'  // Hide scrollbar for IE 10+
  };

  const checkboxContainerStyleMobile = {
    overflowX: 'auto',
    direction: "rtl",
    paddingLeft: '10px',
    borderRadius: '2px',
    position: 'absolute',
    top: '76px', 
    left: '50%',
    whiteSpace: 'nowrap',  // Ensures the chips stay in a single line
    transform: 'translateX(-50%)', // Adjust this value based on half of the container's width
    width: '90%', 
    zIndex: 100,
    WebkitOverflowScrolling: 'touch',  // Better scrolling on iOS devices

    // Styles to hide the scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',  // Hide scrollbar for Chrome, Safari and Opera
    },
    scrollbarWidth: 'none',  // Hide scrollbar for Firefox
    msOverflowStyle: 'none'  // Hide scrollbar for IE 10+
  };

  const center = {
    lat: 31.4500, // Latitude for the center of Israel
    lng: 35.0500, // Longitude for the center of Israel
  };

  const mapOptions = useMemo(() => ({
    mapId: '39a90d03beee0b7b',
    mapTypeId: 'terrain',
    mapTypeControl: false,
    streetView: null,
    gestureHandling: 'greedy',
    streetViewControl: false,
    restriction: {
      latLngBounds: {
        north: 31.92819895778417,/* Latitude of the northeasternmost point */
        south: 31.02819895778417,/* Latitude of the southwesternmost point */
        east:  35.52819895778417,/* Longitude of the northeasternmost point */
        west: 34.42619895778417,/* Longitude of the southwesternmost point */
      },
      strictBounds: true,
    },
  }), []);

  const polygons: PolygonData = polygonData;
  const isMobile = useMobile();
  const navigate = useNavigate();

  
  const { state, dispatch } = useMapContext();
  const { CategoriesFilter, selectedMarker, filteredMarkers } = state;

 const handleFilterChange = (categoryName: string) => {
    const newFilter = { ...CategoriesFilter, [categoryName]: !CategoriesFilter[categoryName] };
    dispatch({ type: 'SET_FILTER', payload: newFilter });
  };

  useEffect(() => {
    if (passedMarker) {
      dispatch({ type: 'SET_SELECTED_MARKER', payload: passedMarker });
    }
  }, [passedMarker]);

  const handleMarkerClick = (marker: PlaceInfoModel) => {
    if (selectedMarker && marker._id === selectedMarker._id) {
      navigate(`/`);
  
    } else {
      navigate(`/${marker.name}`);
      dispatch({ type: 'SET_SELECTED_MARKER', payload: marker });
    }
  };
  

  return (
    <>
      {/* Search bar */}
      {/* <SearchBox filteredMarkers={filteredMarkers} /> */}

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11.5}
        options={mapOptions}
      >
        {/* Render Polygons */}
        {polygons.map((polygon, index) => (
          <Polygon
            key={`${polygon.name}-${index}`}
            paths={polygon.coordinates}
            options={{
              fillColor: polygon.color,
              strokeColor: polygon.color,
              fillOpacity: 0.70,
              strokeOpacity: 0.70,
            }}
          />
        ))}
        {/* Render Markers */}
        {state.filteredMarkers.map((place, index) => (
          <Marker
            key={index}
            position={{ lat: place.location.lat, lng: place.location.lng }}
            title={place.name}
            onClick={() => handleMarkerClick(place)}
            icon={{
              url: `https://resources.be-shvil-yehuda.com/production/assets/icons/Map_Location/36X36_SVG/icon_${place.category}.svg`
            }}
          />
        ))}

        {/* InfoWindows for Markers */}
        <MobileDrawer place={state.selectedMarker}  />
      </GoogleMap>
      {/* Categories filter Chip */}
      <Box sx={isMobile ? checkboxContainerStyleMobile : checkboxContainerStyleWeb}>
  {placesCategories.map((category) => (
    <Chip
      key={category.name}
      label={category.label }
      dir="rtl"
      avatar={
        <Avatar
          dir="rtl"
          src={`https://resources.be-shvil-yehuda.com/production/assets/icons/Map_Pure/35X35_SVG/icon_${category.name}.svg`}
          sx={{ width: 20, height: 20  }}
        />
      }
      variant="filled"
      onClick={() => handleFilterChange(category.name)}
      sx={{  ml: 1, mb: 1, backgroundColor: CategoriesFilter[category.name] ? category.color : '#286c6c', color: '#fff', '& .MuiChip-avatar': { marginRight: '9px', display: 'flex', alignItems: 'right', justifyContent: 'right', width: 17, height: 17, '& img': { objectFit: 'scale-down' } } }}
    />
  ))}
</Box>


      </>
  );
};

export default Map;


