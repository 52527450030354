// src/components/AddMemory.tsx
import React from "react";
import FormBuilder from '../../components/FormBuilder';
import  { memoryCivilFormFields } from '../../consts/adminConsts';
import  { getPathFromComponent } from '../../consts/router';
import useUpload from '../../hooks/useUpload';

interface AddCivilMemoryProps {
    // Add any additional props specific to the About component here
}

function AddCivilMemory(props: AddCivilMemoryProps) {
    const path = getPathFromComponent(AddCivilMemory);
    const { uploadFiles } = useUpload();
    return (
        <div>
            {/* Pass formFields as a prop to FormBuild */}
            <FormBuilder formFields={memoryCivilFormFields} endpoint={path} onSuccess={uploadFiles}/> {/* Use 'formFields' prop here */}
        </div>
    );
};

export default AddCivilMemory;
