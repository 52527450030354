// src/components/AddMemory.tsx
import React from "react";
import FormBuilder from '../../components/FormBuilder';
import  { memoryArmyFormFields } from '../../consts/adminConsts';
import  { getPathFromComponent } from '../../consts/router';
import useUpload from '../../hooks/useUpload';

interface AddArmyMemoryProps {
    // Add any additional props specific to the About component here
}

function AddArmyMemory(props: AddArmyMemoryProps) {
    const path = getPathFromComponent(AddArmyMemory);
    const { uploadFiles } = useUpload();
    return (
        <div>
            {/* Pass formFields as a prop to FormBuild */}
            <FormBuilder formFields={memoryArmyFormFields} endpoint={path} onSuccess={uploadFiles} /> {/* Use 'formFields' prop here */}
        </div>
    );
};

export default AddArmyMemory;
