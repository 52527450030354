import * as React from 'react';
import { loginFields } from '../consts/adminConsts';
import FormBuilder from "../components/FormBuilder";
import {getPathFromComponent} from "../consts/router";
import useAuth from "../hooks/useAuth";
import {Navigate} from "react-router-dom";
import {componentContextTheme, formTheme} from "./../components/style";
import { Box } from '@mui/material';

interface LoginProps {}

function Login(props: LoginProps) {
    const { login, isAuthenticated } = useAuth();
    const endpoint = getPathFromComponent(Login);

    return (
        <div>
            {isAuthenticated() ? (
                <div>
                    {/* Authentication Success admin logged already */}
                    <Navigate to="/admin/dashboard" />
                </div>
            ) : (
                <div>
                    <Box sx={componentContextTheme}>
                    <FormBuilder formFields={loginFields} endpoint={endpoint} onSuccess={login} />
                    </Box>
                </div>

            )}
        </div>

    );
}

export default Login;