import React from 'react';
import { Tab, Tabs,Container } from '@mui/material';
import { adminRoutes } from "../consts/router";
import { Link } from "react-router-dom";
import {adminTabBarTheme} from "./style";

const AdminTabBar = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div >
            < Container  component="main" maxWidth="xs" >
            <Tabs value={value} onChange={handleChange} centered sx={adminTabBarTheme}>
                {adminRoutes.map((route, index) => (
                    <Tab
                        label={route.str}
                        key={index}
                        component={Link}
                        to={route.path}
                    />
                ))}
            </Tabs>
            </Container>
        </div>
    );
};

export default AdminTabBar;
