import React, {useState} from 'react';
import {  Box , AppBar, Toolbar, Typography,Button, InputBase, IconButton, Grid, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemText } from '@mui/material';
import {  Menu as MenuIcon } from '@mui/icons-material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { mainRoutes } from "../consts/router";
import { Link } from "react-router-dom";
import { drawerMenuStyles, logoStyle } from "./style";
import logo from './../assets/logo.png'; 

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    const renderMenuButtons = () => {
        return (
            <div>
                {mainRoutes.map((route, index) => (
                    <Button color="inherit" style={{ fontSize: '20px' }} key={index} component={Link} to={route.path}>
                        {route.str}
                    </Button>
                ))}
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <Link 
                to="https://be-shvil-yehuda.com/" 
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <Typography variant="h4">
                    בשביל יהודה
                </Typography>
            </Link>
        );
    };
    

    const renderDevelop = () => {
        return (
            <Typography variant={ !isMobile ? 'body1' : 'body2' } border={1} p={1} color={'primary'}>
                האתר בפיתוח
            </Typography>
        );
    };

    const renderLogo = () => {
        return (
            <img src={logo} style={logoStyle} alt="Beshvil Yehuda Logo" />
        );
    };

    const renderDrawer = () => {
        return (
            <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)} sx={drawerMenuStyles}>
                <IconButton onClick={toggleDrawer(false)}>
                    {<KeyboardArrowUpIcon style={{ color: 'white' }}/>}
                </IconButton>
                <List>
                    {mainRoutes.map((route, index) => (
                        <ListItem button key={index} component={Link} to={route.path} onClick={toggleDrawer(false)}>
                            <ListItemText primaryTypographyProps={{ textAlign: 'right', color: 'white' }} primary={route.str} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        );
    };

    const renderIcon = () => {
        return (
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
        );
    };
    

    return (
        <div dir={isMobile ? 'ltr' : 'rtl'}>
            <AppBar style={{ background: '#244a4b' }} position="static">
                <Toolbar>

                <Grid container justifyContent={isMobile ? "flex-end" : "flex-start"} alignItems="center" >
                {isMobile &&(
                    <Box p={1} alignItems="left" justifyContent={"flex-start"} style={{ textAlign: 'center' }}>
                        {/* Adjust the width of the first grid to be 20% */}
                        {renderDevelop()}
                    </Box>
                    )}
                    <Grid item xs={ 2} style={{ textAlign: !isMobile ? 'center' : 'right' }}>
                        {/* Adjust the width of the first grid to be 20% */}
                        {isMobile ? (<>{renderLogo()}</> ) : (<>{renderTitle()}</>)}
                    </Grid>
                    <Grid item xs={isMobile ? 5 : 8} style={{ textAlign: 'right' }}>
                        {/* Second grid starting from the right */}
                        {isMobile ? (<>{renderIcon()}</> ) : (<>{renderMenuButtons()}</>)}
                    </Grid>
                    {!isMobile &&(
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                        {/* Adjust the width of the first grid to be 20% */}
                        {renderDevelop()}
                    </Grid>
                    )}
                </Grid>
                </Toolbar>
            </AppBar>

            <div>
                {renderDrawer()}
            </div>
        </div>
    );
};

export default Header;
