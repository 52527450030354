import { BorderBottom, Directions } from "@mui/icons-material";

export const logoStyle = {
    width: 'auto', // Adjust the width as needed
    height: '40px', // Adjust the height as needed

};

export const inputFieldStyle = {
    inputField: {
        
        '& .MuiInputBase-input': {
            color: '#a28d4c', // Adjust text color here
            textAlign: 'right', // Align text to the right
        },
        '& .mui-icon-eye-root': {
            color: '#a28d4c !important', // Icon color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust hover outline color here
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust focused outline color here
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust outline color here
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust outline color on focus here
        },
        '& input:-webkit-autofill': {
            WebkitTextFillColor: '#a28d4c', // Text color for autofill
            WebkitBoxShadow: '0 0 0 1000px #286c6c inset !important', // Background color for autofill
        },
    },
    label: {
        color: '#a28d4c', // Adjust label color here
        '&.Mui-focused': {
            color: '#a28d4c', // Adjust label color on focus here
        },
    },
};

export const selectFieldStyle = {
    paddingTop: 2,
    inputField: {

        color: '#a28d4c', // Adjust label color here
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust hover outline color here
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust focused outline color here
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust outline color here
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Adjust outline color on focus here
        },
        // Additional styles for Select component
        '& .MuiSelect-select': {
            color: '#a28d4c', // Adjust text color for Select component
            textAlign: 'right', // Align text to the right for Select component
        },
        '& .MuiSelect-icon': {
            color: '#a28d4c', // Adjust icon color for Select component
        },
        
        '& input:-webkit-autofill': {
            WebkitTextFillColor: '#a28d4c', // Text color for autofill
            WebkitBoxShadow: '0 0 0 1000px #286c6c inset !important', // Background color for autofill
        },
    },
    label: {
        color: '#a28d4c', // Adjust label color here
        '&.Mui-focused': {
            color: '#a28d4c', // Adjust label color on focus here
        },
    },
    item: {
        
        backgroundColor: '#286c6c',
        color: '#a28d4c', // Adjust label color here
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: 'rgba(36, 74, 75, 0.5)', // Change background color for selected and selected:hover states
            color: '#a28d4c', // Adjust color for selected and selected:hover states
        },
        '&.Mui-focused': {
            backgroundColor: '#286c6c',
            color: '#a28d4c', // Adjust label color on focus here
        },
        '&:hover': {
            backgroundColor: 'rgba(36, 74, 75, 0.5)', // Change hover background color here
        },
        '& .MuiMenuItem-root': {
            borderColor: '#a28d4c', // Adjust outline color on focus here
            backgroundColor: '#286c6c', // Change padding background color for selected states
         
        },
        '& .Mui-root': {
            borderColor: '#a28d4c', // Adjust outline color on focus here
            backgroundColor: '#286c6c', // Change padding background color for selected states
         
        },

    },
};


export const datePickerStyles = {
    container: {
        width: '100%',
        marginY: 1,
        textAlign: 'right',
        '& .MuiPickersDay-daySelected': {
            color: '#a28d4c', // Input text color
            backgroundColor: '#286c6c', 
        },

        '& .MuiInputBase-input': {
            color: '#a28d4c', // Input text color
        },
        '& .MuiInputLabel-outlined': {
            color: '#a28d4c !important', // Label text color
        },
        '& .MuiSvgIcon-root': {
            fill: '#a28d4c !important', // Icon color
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Outline color
        },
        
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c !important', // Hover outline color
        },
        '&:hover': {
            borderColor: '#a28d4c !important', // Hover outline color
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a28d4c', // Focused outline color
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiInputBase-input': {
            color: '#a28d4c', // Focused input text color
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            color: '#a28d4c', // Shrink label text color
        },
        '& input:-webkit-autofill': {
            WebkitTextFillColor: '#a28d4c', // Text color for autofill
            WebkitBoxShadow: '0 0 0 1000px #286c6c inset !important', // Background color for autofill
        },
        // Add more styles as needed
    },
    day: {
        '& .MuiDateCalendar-root': {
        color: '#a28d4c', // Input text color
        backgroundColor: '#286c6c', 
    },
    },
    
    // Add more styles as needed
};


export const componentStyle = {
    // paddingTop: 5,
    // paddingBottom: 5,
    // backgroundColor: '#efeeec', // Color with 50% opacity
};


export const componentContextTheme = {

  
    color: '#a28d4c',
    backgroundColor: '#f0efed',
    direction: 'rtl',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto', // Center horizontally
    width: '100%',

    '@media (max-width: 575px)': {
        minHeight: '75px', // XS screens
    },
    '@media (min-width: 576px) and (max-width: 767px)': {
        minHeight: '125px', // SM screens
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        width: '100%',
        minHeight: '200px', // MD screens
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        width: '90%',
        minHeight: '400px', // LG screens
    },
    '@media (min-width: 1200px) and (max-width: 1599px)': {
        width: '80%',
        minHeight: '500px', // XL screens
    },
    '@media (min-width: 1600px) and (max-width: 1799px)': {
        width: '70%',
        minHeight: '500px', // 2XL screens
    },
    '@media (min-width: 1800px) and (max-width: 2099px)': {
        width: '60%',
        minHeight: '700px', // 3XL screens
    },
    '@media (min-width: 2100px) and (max-width: 2499px)': {
        width: '60%',
        minHeight: '900px', // 4XL screens
    },
    '@media (min-width: 2500px) and (max-width: 10000px)': {
        width: '40%',
        minHeight: '900px', // 4XL screens
    },

};


export const memoryTheme = {
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#286c6c',
    backgroundColor: '#286c6c',
};

export const fallenCard = {
    padding: 3,
    color: '#a28d4c',
    backgroundColor: '#286c6c',
    display: 'flex', flexDirection: 'column'
};

export const fallenDrawerDetails = {

    borderBottom: '1px solid #a28d4c',
    padding: 3,
};

export const paginationTheme = {
    '& .Mui-selected': {
        color: '#a28d4c', // Change the color for the selected page
      },
      '& .MuiPaginationItem-page, & .MuiPaginationItem-ellipsis': {
        color: '#a28d4c', // Change the color for the rest of the pages and ellipsis
      },
      '& .MuiPaginationItem-icon': {
        color: '#a28d4c', // Change the color for the arrow icons
      },
};

export const avatarTheme = {
    width: '100px',
    height: '100px',
  };
  
  export const detailsGridTheme = {
    padding: 2,
    order: { xs: 1, md: 2 },
    borderLeft: '1px solid #a28d4c',
    borderRight: '1px solid #a28d4c',
  };
  
  export const textTypographyTheme = {
    color: '#333',
  };
  
  export const buttonDetailsTheme = {
    color: '#a28d4c', // Text color
    borderColor: '#a28d4c', // Border color
    '&:hover': {
      borderColor: '#c28d4c', // Border color on hover
    },
  };

  export const fallenDrawer = {
    width: '80%',
    flexShrink: 0,


    
    "& .MuiDrawer-paper": {
        width: '100%',
        '@media (min-width: 992px) and (max-width: 1199px)': {
            width: '30%',
        },
        '@media (min-width: 1200px) and (max-width: 1599px)': {
            width: '30%',
        },
        '@media (min-width: 1600px) and (max-width: 1799px)': {
            width: '30%',
        },
        '@media (min-width: 1800px) and (max-width: 2099px)': {
            width: '30%',
        },
      height: '100%',
      direction: "rtl",

      boxSizing: "border-box",
      backgroundColor: '#286c6c',
      padding: "20px", // Optional: add padding for content
      transform: 'translateX(100%)', // Initially position the drawer out of view
      transition: 'transform 0.3s ease-out', // Add transition for smooth sliding effect
    },
  
    "& .MuiBackdrop-root": {
        display: 'none', // Completely remove the backdrop from view
      },
  
  };
  
  
  
export const formTheme = {
    direction: "ltr",

    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#286c6c',
    backgroundColor: '#286c6c',
};

export const adminTabBarTheme = {
    direction: "rtl",
    paddingTop: '15px',
    borderBottom: '0.75px solid #a28d4c',
    "& .MuiTabs-scroller": {
        "& .MuiTabs-indicator": {
            backgroundColor: 'rgba(36, 74, 75, 1)',
            height: 3,
        },
        overflowX: 'auto', // Enable horizontal scrolling if tabs exceed the maximum width
        "& .MuiTab-root": {
            color: '#a28d4c',

            "&.Mui-selected": {
                borderTop: '1px solid #a28d4c',
            },
            "&:hover": {
                backgroundColor: 'rgba(130,131,76,0.2)',
            },
        },
    },
};
export const LoadingSpinnerTheme = {
    color: '#286c6c',
    animationDuration: '550ms',
    position: 'absolute',
    justifyContent: 'center',
};
export const BackDropSpinnerTheme = {
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    backdropFilter: 'blur(0px)', // Remove or set to 'blur(0px)'
    backgroundColor: 'rgba(255, 255, 255, 0)', // Adjust as needed
    justifyContent: 'center',
};
export const drawerMenuStyles = {
    "& .MuiTabs-root": {
        "& .MuiTabs-indicator": {
            backgroundColor: 'rgba(36, 74, 75, 1)',
            height: 3,

        },
        overflowX: 'auto', // Enable horizontal scrolling if tabs exceed the maximum width
        "& .MuiTab-root": {
            "&.Mui-selected": {
                color: 'rgba(36, 74, 75, 1)', // Changing text color for the selected tab
            },
            "&:hover": {
                backgroundColor: 'rgba(36, 74, 75, 0.5)', // Changing background color on hover
            },
        },
    },
    "& .MuiDrawer-paper": {

        backgroundColor: '#286c6c', // Change this to your desired background color
    },

};
export const slideTheme = {
    root: {
        width: '80%',
        margin: '0 auto',
        color: '#286c6c', // Check if color is defined for each element
    },
    thumb: {
        color: '#286c6c',
    },
    track: {
        color: '#286c6c',
    },
    rail: {
        color: '#286c6c',
    },
    valueLabel: {
        color: '#286c6c',
    },
    mark: {
        color: '#286c6c',
    },
};

export const acordionSummeryTheme = {
    backgroundColor: '#3c5354',
    color: '#a28d4c' ,
    borderRadius: '2px',
};

export const accordionTheme = {
    backgroundColor: '#3c5354',
    color: '#a28d4c' ,
    borderRadius: '2px',
};


export const drawerMobileTheme = {
    position: 'fixed',
    direction: 'rtl',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    transition: 'height 0.3s ease',
    overflow: 'hidden',
    // padding: '0px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    backgroundColor: '#286c6c',
    color: '#ffffff',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    overflowY: 'auto',
  };

 export const drawerWebTheme = {
  direction: 'rtl',
  width: '22%',
  position: "absolute",
  right: 0,
  minWidth: "220px",
  borderRadius: '20px 0px 0px 20px',
  zIndex: 1300,
  transition: 'width 0.5s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  backgroundColor: '#286c6c',
  color: '#ffffff',
  padding: '5px 10px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  overflowY: 'auto',
};

export const drawerswapIcon = {
    display: 'flex',
    marginRight: 'auto', 
    color: '#fffff',
  };
  
  export const drawerCloseIcon = {
    display: 'flex',
    marginRight: 'auto',  
    color: '#fffff',
  };

  export  const drawerPlaceHeights = {
    closed: '0vh',
    titleMobile: '10vh',
    halfMobile: '50vh',
    fullMobile: '75vh',
    web: '100vh',
  };