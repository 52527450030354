import React from "react";
import Typography from "@mui/material/Typography";
import {Grid, Box, Divider} from "@mui/material";
import {componentContextTheme} from "../../components/style";

interface VisionProps {
    // Add any additional props specific to the vision component here
    title: string;
}

function Vision({ title }: VisionProps) {
    return (
        <Grid item>

                <Typography variant="h4" color='#286c6c' gutterBottom textAlign="right">
                    {title}
                </Typography>
                <Typography color='#000' variant="h6" textAlign="right">
                    {/* Your about content goes here */}
                    מרחבי יהודה 'נפתחו' לקהל הרחב החל ממלחמת ששת הימים.
                    <br/>
                    לאחר המלחמה זכינו במרחבי ארץ ספוגים בהיסטוריה יהודית ארוכת שנים ומשופעים 
                    באתרי מורשת.
                    <br/>
                    יש לנו את הזכות לאסוף את כל הטוב הזה ולהנגיש אותו בראש ובראשונה לחיילי ומפקדי 
                    צה"ל המשרתים בגזרה, למתיישבים החיים כאן ולמבקרים הפוקדים את האתרים בימי 
                    שגרה ובמועדים.
                    <br/>
                    החזון שלנו הוא שכל חייל המשרת בגזרה, ממפקדה ועד אחרון החיילים, ישתמשו בכלים 
                    הרבים שמיזם זה מציע כדי להתחבר ולהעמיק שורשים אל המקום ומתוך כך אל המשימה 
                    הצבאית שלהם.
                    <br/>
                    המתיישבים, מדור המייסדים ועד הילדים בגן ובבית הספר, החיים במרחב זה לא תמיד 
                    חשופים ומודעים לאוצר הטמון בחבלי ארץ אלו. מיזם זה מנגיש להם, אם בשילוט באתרי 
                    מורשת נבחרים שיוצבו סמוך לדרכים בהם נוסעים יום-יום ואם בספר שיהיה בבתיהם 
                    ואם באתר אינטרנט עשיר ונגיש. 
                    <br/>
                    המבקרים והמתעניינים בחבל ארץ זה יוכלו למצוא מידע שימושי רב בכלים שהמיזם מציע 
                    וכך יהפכו את הביקור ביהודה למשמעותי ומלמד יותר.
                    <br/>
                    חינוך – מיזם זה, ראוי לו שיהיה חלק ממערכת החינוך במועצות המקומיות והאזורית. 
                    הכלים שהמיזם מציע, החומרים שהוא מעלה על הכתב ולרשתות יכולים להיות בסיס להיכרות 
                    מעמיקה של דור ההמשך ביהודה, עם העבר המפואר של האזור.  
                    <br/>
                    בשורה לגזרות נוספות באוגדה - זהו מיזם פורץ דרך ויכול להיות מודל לחיקוי בגזרות 
                    אחרות אנו בטוחים שהצלחתו ביהודה תשוכפל בגזרות נוספות והמיזם יאומץ בחבלי ארץ 
                    יקרים נוספים.
                    <br/><br/>
                    בתפילה שנצליח להנחיל בליבנו את כל הטוב שההר הזה מציע, נגדל מזה ונעמיק את
                    שורשינו ביהודה ובארץ ישראל כולה
                </Typography>

        </Grid>
    );
}

export default Vision;
