import React, { useState, useEffect } from 'react';
import useMobile from '../hooks/useMobile';
import { Box, Chip, IconButton } from '@mui/material';
import MaximizeIcon from '@mui/icons-material/Maximize';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PlaceInfo as PlaceInfoModel } from '../consts/models';
import { drawerMobileTheme, drawerWebTheme, drawerPlaceHeights, drawerswapIcon, drawerCloseIcon } from './style';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import ListedPlaces from './ListedPlaces';
import { useMapContext } from "./layouts/MapContext";
import PlaceDetails from './PlaceDetails';
import { DrawerState } from './../consts/MapConsts';

interface MobileDrawerProps {
  place: PlaceInfoModel | null;
}

interface NavigationIconsProps {
  drawerState: DrawerState;
  setNextState: () => void; 
  setPrevState: () => void;
  closeDrawer: () => void;
  place: PlaceInfoModel | null;
  isMobile: boolean; 
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ place }) => {
  const { state, dispatch } = useMapContext();
  const { drawerState } = state;
  const isMobile = useMobile();
  const navigate = useNavigate();

  const handlers = useSwipeable({
    onSwipedUp: () => setNextState(),
    onSwipedDown: () => setPrevState(),
    onSwipedLeft: () => closeDrawer(),
    trackMouse: true,
    delta: 100, // Increase the delta for less sensitivity, default is usually 10

    trackTouch: true
  });
  const closeDrawer = () => {
    dispatch({ type: 'SET_SELECTED_MARKER', payload: null });
    dispatch({ type: 'SET_DRAWER_STATE', payload: 'closed' });
    navigate('/');
  };

  const setNextState = () => {
    if (drawerState !== 'fullMobile') {
      const nextDrawerState = drawerState === 'halfMobile' ? 'fullMobile' : 'halfMobile';
      dispatch({ type: 'SET_DRAWER_STATE', payload: isMobile ? nextDrawerState : 'web' });
    }
  };
  const setPrevState = () => {
    const prevDrawerState = drawerState === 'fullMobile' ? 'halfMobile' : 'closed';
    dispatch({ type: 'SET_DRAWER_STATE', payload: prevDrawerState });
    if (prevDrawerState === 'closed') closeDrawer();
  };

  useEffect(() => {
    if (place) {
      dispatch({ type: 'SET_DRAWER_STATE', payload: isMobile ? 'halfMobile' :  'web' });
    }
  }, [place]);

  return (
    <>
      <Box sx={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', textAlign: 'center', width: '100%', zIndex: 1301 }}>
        {drawerState === 'closed' && (
          <Chip label="הצג רשימה" onClick={setNextState} color='primary' clickable />
        )}
      </Box>
      {drawerState !== 'closed' && !place && (
        <Box sx={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', textAlign: 'center', width: '100%', zIndex: 1301 }}>
          <Chip label="הצג במפה" onClick={closeDrawer} color='primary' clickable />
        </Box>
      )}
      {drawerState !== 'closed' && (
        <Box sx={{ ...(isMobile ? drawerMobileTheme : drawerWebTheme), height: drawerPlaceHeights[drawerState], overflow: 'hidden' }} {...handlers}>
          <>
            <NavigationIcons drawerState={drawerState} setNextState={setNextState} setPrevState={setPrevState} closeDrawer={closeDrawer} place={place} isMobile={isMobile} />
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
              {place ? <PlaceDetails place={place} /> : <ListedPlaces />}
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

const NavigationIcons: React.FC<NavigationIconsProps> = ({ drawerState, setNextState, setPrevState, closeDrawer, place, isMobile }) => (
  <Box sx={{ display: 'flex', color: '#ffffff' }}>
    {isMobile && (
      <Box sx={drawerswapIcon}>
        {drawerState !== 'closed' && (
          <>
            {drawerState === 'fullMobile' ? (
              <IconButton onClick={setPrevState}>
                <KeyboardArrowDownIcon style={{ color: 'white' }} />
              </IconButton>
            ) : (
              <IconButton onClick={setNextState}>
                <MaximizeIcon style={{ color: 'white' }} />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )}
    <Box sx={drawerCloseIcon}>
      {place && (
        <IconButton onClick={closeDrawer}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      )}
    </Box>
  </Box>
);

export default MobileDrawer;
