import React, { useState, useEffect } from 'react';
import { Box, Grid, Button } from '@mui/material';

interface Item {
    id: number;
    component: React.ReactNode;
    title: string;
}

interface WebViewProps {
    items: Item[];
    tabId: number;
}

const WebView: React.FC<WebViewProps> = ({ items, tabId }) => {
    const [activeTab, setActiveTab] = useState<number>(tabId);

    useEffect(() => {
        setActiveTab(tabId);
    }, [tabId]);

    return (
        <Box>
            <Grid container>
                {/* Sidebar for buttons */}
                <Grid item xs={2} sx={{
                    pr: 2,
                    minHeight: '420px',
                    bgcolor: '#286c6c',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '200px', // Fixed width for the sidebar
                }}>
                    {items.map((item, index) => (
                        <Button
                            key={item.id}
                            sx={{
                                width: 'auto',
                                maxWidth: '90%',
                                justifyContent: 'flex-start',
                                color: 'white',
                                bgcolor: index === activeTab ? '#90c340' : 'inherit',
                                '&:hover': {
                                    color: '#90c340',
                                },
                                mt: 1,
                            }}
                            onClick={() => setActiveTab(index)}
                        >
                            {item.title}
                        </Button>
                    ))}
                </Grid>
                {/* Main content area */}
                <Grid item xs sx={{ p: 5 }}>
                    {items.map((item, index) => (
                        <Box 
                            key={item.id}
                            sx={{ display: index === activeTab ? 'block' : 'none' }}
                        >
                            <Grid>
                                {item.component}
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

export default WebView;
