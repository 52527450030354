// FormFieldBuilder.js
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Avatar, Button,InputLabel, Typography, FormControl, MenuItem } from "@mui/material";
import { inputFieldStyle, datePickerStyles, selectFieldStyle } from './style';
import LockOutlined from "@mui/icons-material/LockOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from "@mui/material/Box";

/**
 * Form values type - input values as a string or Date
 */
export interface FormValuesType {
    [key: string]: string | number | Date | FileList | null;
}

/**
 * Field type for generic form
 */
export type FieldType = 'number'| 'filesInput' | 'select' |'text' | 'password' | 'email' | 'date' | 'textarea' | 'logo' | 'title' | 'submit';

export interface FieldComponent {
    label: string;
    name: string;
    type: FieldType;
    options?: { value: string; label: string }[];
}

/**
 * Generic full form component
 */
interface FieldComponentProps extends FieldComponent {
    formValues: FormValuesType;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (key: keyof FormValuesType, date: Date | null) => void;
    handleSelectChange: (event: SelectChangeEvent<string>, name: keyof FormValuesType) => void;
    handleFileInputChange: (files: FileList | null, name: keyof FormValuesType) => void;
}

export const fieldComponents: Record<string, React.FC<FieldComponentProps>> = {
    date: ({ label, name, formValues, handleDateChange }) => (
        <FormControl fullWidth >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    value={formValues[name] as Date | null}
                    onChange={(date) => handleDateChange(name, date)}
                    slotProps={{ textField: { sx: datePickerStyles.container }, day: { sx: datePickerStyles.day } }}
                />
            </LocalizationProvider>
        </FormControl>
    ),
    text: ({ label, name, formValues, handleInputChange }) => (
        <TextField
            label={label}
            name={name}
            value={formValues[name] as string}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputProps={{
                sx: inputFieldStyle.inputField,
            }}
            InputLabelProps={{
                sx: inputFieldStyle.label,
            }}
        />
    ),
    number: ({ label, name, formValues, handleInputChange }) => (
        <TextField
            label={label}
            name={name}
            value={formValues[name] as number}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputProps={{
                sx: inputFieldStyle.inputField,
            }}
            InputLabelProps={{
                sx: inputFieldStyle.label,
            }}
        />
    ),
    password: ({ label, name, formValues, handleInputChange }) => (
        <TextField
            label={label}
            name={name}
            value={formValues[name] as string}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            type="password"
            id="password"
            autoComplete="current-password"
            required
            InputProps={{
                sx: inputFieldStyle.inputField,
            }}
            InputLabelProps={{
                sx: inputFieldStyle.label,
            }}

        />
    ),

    email: ({ label, name, formValues, handleInputChange }) => (
        <TextField
            label={label}
            name={name}
            value={formValues[name] as string}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            autoComplete="email"
            autoFocus
            type="email"
            InputProps={{
                sx: inputFieldStyle.inputField,
            }}
            InputLabelProps={{
                sx: inputFieldStyle.label,
            }}

        />
    ),

    textarea: ({ label, name, formValues, handleInputChange }) => (
        <TextField
            label={label}
            name={name}
            value={formValues[name] as string}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            id="outlined-multiline-static"
            multiline
            rows={4}
            InputProps={{
                sx: inputFieldStyle.inputField,
            }}
            InputLabelProps={{
                sx: inputFieldStyle.label,
            }}
        />
    ),
    select: ({ label, name, options, formValues, handleSelectChange }) => {
        return (
            <FormControl fullWidth >
            <InputLabel sx={selectFieldStyle.label} id="select-label">{label}</InputLabel>
            <Select
            labelId="select-label"
              label={label}
              name={name}
              value={formValues[name] as string}
              onChange={(event) => handleSelectChange(event, name)}
              fullWidth
              sx={selectFieldStyle.inputField}
              inputProps={{
                sx: selectFieldStyle.label, 
              }}
              
            >
              {options &&
                options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value} sx={selectFieldStyle.item}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl >
        );
    },
    filesInput: ({ label, name, formValues, handleFileInputChange }) => {
        const selectedFiles = formValues[name] as FileList;
        return (
            <>
                <input
                    id={`file-input-${name}`}
                    type="file"
                    accept=".png, .jpg, .jpeg, .mp4"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileInputChange(event.target.files, name)}
                />
                <label htmlFor={`file-input-${name}`}>
                <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        color: '#a28d4c',
                        borderColor: '#a28d4c',
                        '&:hover': {
                            borderColor: '#a28d4c',
                            backgroundColor: '#a28d4c', 
                            color: '#244a4b', 
                        },
                    }}
                >
                    {label}
                </Button>

                </label>
                {selectedFiles && (
                <Typography variant="body2" color="#a28d4c">
                    {Array.from(selectedFiles).map(file => file.name).join(', ')}
                </Typography>
            )}

            </>
        );
    },

      
      
    logo: () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar sx={{ m: 1, bgcolor: '#a28d4c' }}>
                <LockOutlined sx={{ color: '#244a4b' }}/>
            </Avatar>
        </Box>
    ),

    title: ({label}) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '0.75px solid #a28d4c', }}>
            <Typography component="h1" variant="h5" dir="rtl" textAlign="center"  color='#a28d4c'>
                {label}
            </Typography>
        </Box>
    ),

    submit: ({ label }) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
                type="submit"
            
                sx={{
                    color: '#244a4b',
                    backgroundColor: '#a28d4c',
                    '&:hover': {
                        color: '#a28d4c',
                        borderColor: '#a28d4c !important',
                        backgroundColor: '#244a4b',
                    },
                    mt: '3px', 
                    mb: '2px', 
                    border: '1px solid transparent',
                }}
            >
                {label}
            </Button>
        </Box>
    ),
};

