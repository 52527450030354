// Uploader.tsx
import apiService from '../services/apiService';
import { AxiosInstance } from 'axios';
import { UPLOAD_URL } from "./../consts/UrlConsts"

interface Options {
  useTransferAcceleration: boolean;
  chunkSize?: number;
  threadsQuantity?: number;
  file: File;
  filePath?: string;

}

interface Part {
  PartNumber: number;
  signedUrl: string;
}

interface UploadedPart {
  PartNumber: number;
  ETag: string;
}

interface AWSFileDataOutput {
  fileId: string;
  fileKey: string;
}

interface UploaderProgressEvent extends ProgressEvent {
  sent: number;
  total: number;
  percentage: number;
}

class Uploader {
  private useTransferAcceleration: boolean;
  private chunkSize: number;
  private threadsQuantity: number;
  private timeout: number;
  private file: File;
  private fileName?: string;
  private aborted: boolean;
  private uploadedSize: number;
  private progressCache: { [key: number]: number };
  private activeConnections: { [key: number]: XMLHttpRequest };
  private parts: Part[];
  private uploadedParts: UploadedPart[];
  private fileId?: string;
  private fileKey?: string;
  private onProgressFn: (event: ProgressEvent) => void;
  private onErrorFn: (error: Error) => void;
  private baseURL: string;
  private api: AxiosInstance;

  constructor(options: Options) {
    this.useTransferAcceleration = options.useTransferAcceleration;
    this.chunkSize = Math.max(options.chunkSize || 5242880, 5242880); // 5MB
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.timeout = 0;
    this.file = options.file;
    this.fileName = options.filePath;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = undefined;
    this.fileKey = undefined;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.baseURL = UPLOAD_URL;
    this.api = apiService.getAxiosInstance();
  }

  public start(): void {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      let fileName = this.fileName;

      const videoInitializationUploadInput = {
        name: fileName,
      };

      const initializeResponse = await this.api.request<AWSFileDataOutput>({
        url: "/initialize",
        method: "POST",
        data: videoInitializationUploadInput,
        baseURL:this.baseURL
      });

      const { fileId, fileKey } = initializeResponse.data;
      this.fileId = fileId;
      this.fileKey = fileKey;

      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const AWSMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfParts,
      };

      const urlsResponse = await this.api.request<{ parts: Part[] }>({
        url: this.useTransferAcceleration ? "/getPreSignedTAUrls" : "/getPreSignedUrls",
        method: "POST",
        data: AWSMultipartFileDataInput,
        baseURL:this.baseURL
      });

      const newParts = urlsResponse.data.parts;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      this.complete(error as Error);
    }
  }

  private sendNext(retry: number = 0): void {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }
      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          if (retry <= 6) {
            retry++;
            const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
            console.log(`Part#${part.PartNumber} failed to upload, retrying...`);
            wait(2 ** retry * 100).then(() => {
              this.parts.push(part);
              this.sendNext(retry);
            });
          } else {
            console.log(`Part#${part.PartNumber} failed to upload, giving up`);
            this.complete(error as Error);
          }
        });
    }
  }

  private async sendChunk(chunk: Blob, part: Part, sendChunkStarted: () => void): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private async upload(chunk: Blob, part: Part, sendChunkStarted: () => void): Promise<number> {
    return new Promise((resolve, reject) => {
      if (!this.fileId || !this.fileKey) {
        reject(new Error("Missing file ID or key"));
        return;
      }

      const xhr = new XMLHttpRequest();
      this.activeConnections[part.PartNumber - 1] = xhr;
      xhr.timeout = this.timeout;
      sendChunkStarted();

      xhr.upload.addEventListener("progress", (event) => {
        this.handleProgress(part.PartNumber - 1, event);
      });

      xhr.addEventListener("error", (event) => {
        this.handleProgress(part.PartNumber - 1, event);
      });

      xhr.addEventListener("abort", (event) => {
        this.handleProgress(part.PartNumber - 1, event);
      });

      xhr.addEventListener("loadend", (event) => {
        this.handleProgress(part.PartNumber - 1, event);
      });

      xhr.open("PUT", part.signedUrl);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const ETag = xhr.getResponseHeader("ETag");
          if (ETag) {
            const uploadedPart = {
              PartNumber: part.PartNumber,
              ETag: ETag.replaceAll('"', ""),
            };
            this.uploadedParts.push(uploadedPart);
            resolve(xhr.status);
            delete this.activeConnections[part.PartNumber - 1];
          }
        }
      };

      xhr.onerror = (error) => {
        reject(error);
      };

      xhr.ontimeout = (error) => {
        reject(error);
      };

      xhr.onabort = () => {
        reject(new Error("Upload canceled"));
      };

      xhr.send(chunk);
    });
  }

  private handleProgress(partNumber: number, event: ProgressEvent<EventTarget>) {
    if (event.lengthComputable) {
      const loaded = event.loaded;
      this.progressCache[partNumber] = loaded;
      const inProgress = Object.values(this.progressCache).reduce((memo, value) => memo + value, 0);
      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);
      this.onProgressFn({
        ...event, 
        sent,
        total,
        percentage,
    } as UploaderProgressEvent);
    }
  }
  
  private async complete(error?: Error): Promise<void> {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error as Error);
    }
  }

  private async sendCompleteRequest(): Promise<void> {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      await this.api.request({
        url: "/finalize",
        method: "POST",
        data: videoFinalizationMultiPartInput,
        baseURL:this.baseURL,
      });
    }
  }
  public onProgress(onProgress: (event: ProgressEvent) => void): Uploader {
    this.onProgressFn = onProgress;
    return this;
  }


  public onError(onError: (error: Error) => void): Uploader {
    this.onErrorFn = onError;
    return this;
  }

  public abort(): void {
    Object.keys(this.activeConnections).forEach((id) => {
      this.activeConnections[Number(id)].abort();
    });
    this.aborted = true;
  }
}

export default Uploader;
