import { useReducer } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

export const useForm = <T extends {}>(initialFormValues: T) => {
    const [formValues, dispatch] = useReducer(
        (state: T, action: any) => {
            switch (action.type) {
                case "SET_FIELD":
                    return { ...state, [action.fieldName]: action.value };
                case "SET_DATE":
                    return { ...state, [action.fieldName]: action.value };
                case "SET_SELECT":
                    return { ...state, [action.fieldName]: action.value };
                case "SET_FILES":
                    return { ...state, [action.fieldName]: action.value };
                case "RESET_FORM":
                    return initialFormValues; // Resetting the form to initial values
                default:
                    return state;
            }
        },
        initialFormValues
    );

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { name, value } = event.target;
        dispatch({ type: "SET_FIELD", fieldName: name, value });
    };

    const handleDateChange = (key: keyof T, date: Date | null): void => {
        dispatch({ type: "SET_DATE", fieldName: key, value: date });
    };

    const handleSelectChange = (
        event: SelectChangeEvent<string>, // Updated event type
        name: keyof T,
    ): void => {
        dispatch({ type: "SET_SELECT", fieldName: name, value: event.target.value });
    };

    const handleFileInputChange = (
        files: FileList | null,
        name: keyof T
      ): void => {
        const existingFiles = formValues[name] as FileList;
        const updatedFilesArray: File[] = existingFiles ? Array.from(existingFiles) : [];
      
        if (files && files.length > 0) {
          updatedFilesArray.push(...Array.from(files));
        }
      
        dispatch({ type: "SET_FILES", fieldName: name, value: updatedFilesArray });
      };
      
          // Reset function
    const resetForm = (): void => {
        dispatch({ type: "RESET_FORM" });
    };
      
    return {
        formValues,
        handleInputChange,
        handleDateChange,
        handleSelectChange,
        handleFileInputChange,
        resetForm,
    };
};
