import React, { useState } from "react";
import { IconButton, InputBase, Box, Avatar, Typography, Paper } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { PlaceInfo as PlaceInfoModel } from "../consts/models";
import { placesCategories } from "../consts/MapConsts";
import {useNavigate } from "react-router-dom";
import { useMapContext } from "./layouts/MapContext"

// Define constant for search bar styles
const searchBarStyle = {
  direction: "rtl",
  color: '#fff',
  backgroundColor: '#286c6c',
  padding: '5px 10px ',

};

// Define constant for box styles
const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: '5px',
};

interface SearchBoxProps {
  searchText: string;
  onSearchTextChange: (text: string) => void;
}


// Render the search bar component
const SearchBox: React.FC<SearchBoxProps> = ({ searchText, onSearchTextChange }) => {


  const isSearchTextEmpty = searchText.trim() === "";

  return (
    <Box sx={searchBarStyle}>
      <Box sx={{...boxStyle, m: 2, backgroundColor: '#fff',color: '#244a4b', borderRadius: '25px'}}>
        <InputBase
          placeholder="חיפוש..."
          inputProps={{ 'aria-label': 'search', style: { color: '#inherit' } }}
          value={searchText}
          onChange={(e) => onSearchTextChange(e.target.value)}
          sx={{ flex: 1, m: 1 }}
        />
        {!isSearchTextEmpty && (
          <IconButton
            color="inherit"
            onClick={() => onSearchTextChange("")}
            aria-label="clear search"
          >
            <Clear />
          </IconButton>
        )}
        <IconButton color="inherit" aria-label="search">
          <Search />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SearchBox;
