import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { BASE_URL } from "./../consts/UrlConsts"

export interface ApiResponse {
    success: boolean;
    data: any; 
    // Add other properties based on your API response structure
}

let axiosInstance: AxiosInstance | null = null;

export const getAxiosInstance = (): AxiosInstance => {
    if (!axiosInstance) {
        const token = localStorage.getItem('accessToken');
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': '',
        }
        if (token) {
            console.log("Token on setAuthToken: ", token)
            headers['Authorization'] = `${token}`;
        }
        axiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: headers,
        });


    }
    return axiosInstance;
};

const apiService = {
    setAuthToken: (token: string | null): void => {
        if (token) {
            console.log("Token on setAuthToken: ", token)
            getAxiosInstance().defaults.headers.common['Authorization'] = `${token}`;
        } else {
            delete getAxiosInstance().defaults.headers.common['Authorization'];
        }
    },
    get: async (endpoint: string): Promise<ApiResponse> => {
        try {
            const response = await getAxiosInstance().get<ApiResponse>(endpoint);
            return response.data;
        } catch (error: any) {
            throw new Error(`Error fetching data: ${(error as Error).message}`);
        }
    },

    post: async (endpoint: string, data: any): Promise<ApiResponse> => {
        try {
            const response = await getAxiosInstance().post<ApiResponse>(endpoint, data);
            return response.data;
        } catch (error: any) {
            throw new Error(`Error posting data: ${(error as Error).message}`);
        }
    },

    // Other methods...

    // For instance, a method to update the token and set it in axios headers
    updateToken: (newToken: string): void => {
        apiService.setAuthToken(newToken);
    },
    getAxiosInstance,
};

export default apiService;
