// src/contexts/MapContext.tsx
import React, { createContext,useMemo, useReducer, useContext, Dispatch, ReactNode, useEffect } from 'react';
import { MapState, MapAction } from '../../consts/MapConsts';
import { mapReducer, initialState } from '../reducers/MapReducer';
import { PlaceInfo as PlaceInfoModel } from "../../consts/models";

interface MapContextType {
  state: MapState;
  dispatch: Dispatch<MapAction>;
  places: PlaceInfoModel[]; // Assuming PlaceInfo is your place model type
}

interface MapProviderProps {
  children: ReactNode;
  places: PlaceInfoModel[]; // Add places as a prop
}

export const MapContext = createContext<MapContextType | undefined>(undefined);

export const MapProvider: React.FC<MapProviderProps> = ({ children, places }) => {
  const [state, dispatch] = useReducer(mapReducer, initialState);
  const { CategoriesFilter } = state;

  // Filter places based on CategoriesFilter
  const filteredPlaces = useMemo(() => places.filter(place => {
    if (Object.keys(CategoriesFilter).length === 0) {
      return true;
    } else {
      return CategoriesFilter[place.category];
    }
  }), [places, CategoriesFilter]);

  // Update filtered markers when CategoriesFilter change
  useEffect(() => {
    // Dispatch action to update filtered markers
    dispatch({ type: 'SET_FILTERED_MARKERS', payload: filteredPlaces });
    
  }, [CategoriesFilter, dispatch]); // Depend only on CategoriesFilter and dispatch

  return (
    <MapContext.Provider value={{ state, dispatch, places }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapContext must be used within a MapProvider');
  }
  return context;
};
