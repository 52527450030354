import { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { PlaceInfo as PlaceInfoModel } from "../consts/models";

// Define a type for the fetch result
type FetchResult<T> = {
    data: T | null;
    loaded: boolean;
    error: boolean;
};

// Updated useFetch function
const useFetch = <T,>(url: string): FetchResult<T> => {
    const [data, setData] = useState<T | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiService.get(url);
                setData(response.data); 
            } catch (err) {
                console.error("Error fetching data:", err);
                setError(true);
            } finally {
                setLoaded(true);
            }
        };

        fetchData();
    }, [url]);

    return { data, loaded, error };
};

export const usePlaces = (category?: any) => {
    const path = category ? `/category/${category}` : '';
    const { data, loaded, error } = useFetch<{ places: PlaceInfoModel[] }>(`/places${path}`);
    const places = data?.places || [];  

    return { places, loaded, error };
};

export const usePlace = (id: string) => {
    const { data, loaded, error } = useFetch<{ place: PlaceInfoModel }>(`/places/id/${id}`);
    const place = data?.place || null; 

    return { place, loaded, error };
};
