import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';

const videoContainerStyle: React.CSSProperties = {
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 aspect ratio
  height: 0,
  overflow: 'hidden',
  width: '100%',
  background: '#000',
  marginBottom: '20px' // Optional, for spacing
};

const iframeStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%'
};

interface SymbolProps {
  title: string;
}

const Video: React.FC<SymbolProps> = ({ title }) => {
  return (
    <Grid item>
      <Typography variant="h4" color="#286c6c" textAlign="right">
        {title}
      </Typography>
    
      <Typography color='#000' variant="h6" textAlign="right">
          הסרטון לפניכם מתאר את המיזם שביל יהודה, מוזמנים לצפות בסרטון שלנו, נשמח אם תשתפו אותו לחברים ולמשפחה!
           <br/>
           <br/>
      </Typography>

      <Box style={videoContainerStyle}>
        <iframe
          src="https://player.vimeo.com/video/854634359?h=6d11db5b45"
          style={iframeStyle}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </Grid>
  );
};

export default Video;
